import Vue from 'vue'
import zh from './zh';
import en from './en2';
import vi from './vi';
import zh_tw from './zh_tw';

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: (function () {
        if (localStorage.getItem('lang_help')) {
            return localStorage.getItem('lang_help')
        }
        return 'zh'
    }()),
    messages: {
        zh,
        en,
        vi,
        zh_tw
    },
    silentTranslationWarn: true
})

export default i18n