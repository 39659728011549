<template>
  <div class="header">
    <div class="center">
      <div class="select">
        <div class="title_box" @click="onClick()">
          <div class="title">{{ langTitle }}</div>
          <van-icon name="arrow-down" :class="this.show === false ? 'down_icon' : 'icon'" />
        </div>
        <ul class="list" v-show="show">
          <li v-for="(item, index) in langList" :key="index" :class="classIndex == index ? 'active' : ''"
            @click="onLang(item, index)">{{ item.title }}</li>
        </ul>
      </div>
      <Close />
    </div>

    <van-dialog v-model="dialogShow" :showCancelButton="false" :showConfirmButton="false">
      <div class="dialog_title">{{ $t("Header.d1") }}</div>
      <div class="dialog_center">{{ $t("Header.d2") }}"{{ lang }}"?</div>
      <div class="dialog_btns">
        <div class="cancel" @click="dialogShow = false">{{ $t("Header.d3") }}</div>
        <div class="sure" @click="Language">{{ $t("Header.d4") }}</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { Icon, Dialog } from "vant";
import Close from "../close/index.vue"
import CommonUtil from '@/utils/commonUtil'
Vue.use(Icon);
Vue.use(Dialog);
export default {
  components: {
    Close,
  },
  data() {
    return {
      show: false,
      langList: [
        { lang: "zh", title: "简体中文", index: 0 },
        { lang: "en", title: "English", index: 1 },
        { lang: "vi", title: "Tiếng Việt", index: 2 },
        { lang: "zh_tw", title: "繁體中文", index: 3 },
      ],
      langTitle: '',
      lang: "",
      classIndex: 0,
      dialogShow: false,
      langData: {},
    };
  },
  created(){
    // 订阅 设置滚动列表
    this.$Subscribe.subscribe(CommonUtil.EventName.imgMapChange, this.setLang)
  },
  mounted() {
     this.$nextTick(()=>{
      this.setLang()
    });
  },
  methods: {
    onClick() {
      if (this.show === false) {
        this.show = true;
      } else if (this.show === true) {
        this.show = false;
      }
    },
    onLang(item, index) {
      this.langData = item;
      this.langData.index = index;
      this.lang = item.title;
      this.dialogShow = true;
    },

    Language() {
      this.$i18n.locale = this.langData.lang;
      localStorage.setItem("lang_help", this.langData.lang);
      this.dialogShow = false;
      this.lang = this.langData.title;
      this.langTitle = this.langData.title;
      this.classIndex = this.langData.index
      this.show = false
      this.$emit('Language', this.langData)
    },
    setLang() {
      let lang = localStorage.getItem("lang_help");
      // console.log(lang,"这里有没有=======")
      if (lang) {
        this.$i18n.locale = lang;
        this.langList.forEach((item) => {
          if (item.lang == lang) {
            this.langData = item;
            this.lang = this.langData.title;
            this.langTitle = this.langData.title;
            this.classIndex = this.langData.index;
          }
        });
      } else {
        this.$i18n.locale = this.langList[0].lang;
        localStorage.setItem("lang_help", "zh");
        this.lang = this.langList[0].title;
        this.langTitle = this.langList[0].title;
        this.classIndex = this.langList[0].index;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  //   position: relative;
  background-color: #000;
  background-repeat: no-repeat;
  background-position: top;
  background-size: auto 26.667vw;
  height: 29.333vw;
  background-image: url("../../images/pic47.jpg");
  background-position-x: 67% !important;

  .center {
    width: 100%;
    height: 100%;
    position: relative;

    .select {
      position: absolute;
      top: 4.373vw;
      right: 15.33vw;
      padding-left: 1.067vw;
      padding-right: 1.067vw;
      width: 21.333vw;
      border: 0.24vw solid #979797;
      border-radius: 0.533vw;
      color: #aaa;

      .title_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          white-space: nowrap;
          min-height: 4.8vw;
          font-size: 2.43vw;
        }

        .icon {
          transform: rotate(180deg);
          transition: all 0.2s;
          font-size: 2.13vw;
        }

        .down_icon {
          transform: rotate(0deg);
          transition: all 0.2s;
          font-size: 2.13vw;
        }
      }

      .list {
        position: absolute;
        width: 100%;
        max-height: 40vw;
        overflow: auto;
        list-style: none;
        top: 4.8vw;
        left: 0;
        background: #fff;
        border-radius: 0.267vw;
        box-shadow: 0 1.333vw 2.533vw 0 RGB(0 0 0 / 20%);
        z-index: 9;

        li {
          font-size: 2.4vw;
          line-height: 8vw;
          cursor: pointer;
          text-align: center;
        }

        .active {
          background: #f3e5ca;
          color: #000;
        }
      }
    }
  }

  ::v-deep .van-dialog {
    width: 80vw;
  }

  .dialog_title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10.4vw;
    background-image: linear-gradient(180deg, #e5c99e, #f3e5ca);
    font-size: 4.8vw;
    color: #4c3432;
    text-align: center;
    font-weight: 400;
    text-align: center;
  }

  .dialog_center {
    height: 19.2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.467vw;
    color: #666;
    font-weight: 400;
  }

  .dialog_btns {
    display: flex;
    justify-content: space-around;
    margin-bottom: 25px;

    div {
      width: 32vw;
      height: 9.6vw;
      line-height: 9.6vw;
      justify-content: center;
      font-size: 4vw;
      border-radius: 5.333vw;
      cursor: pointer;
      text-align: center;
    }

    .cancel {
      background-image: linear-gradient(180deg, #e5e4e5, #cfcfce);
      color: #666;
    }

    .sure {
      background-image: linear-gradient(180deg, #f3e5ca, #e5c99e);
      color: #634414;
    }
  }
}</style>
