const vi = {
  "Header": {
    "d1": `Cài đặt ngôn ngữ`,
    "d2": `Chuyển ngôn ngữ sang`,
    "d3": `Hủy`,
    "d4": `Xác nhận`
  },
  "Bacarat": {
    "d1": `Baccarat Cổ Điển`,
    "d2": `1.Quy tắc chơi`,
    "d3": `Dùng 8 bộ bài (bỏ các quân Joker), tất cả 416 lá bài;`,
    "d4": `"Người Chơi" và "Nhà Cái" mỗi bên phát 2 lá bài, "Người Chơi" trước, "Nhà Cái" sau (Thứ tự mở bài cũng vậy). Nếu vòng đầu chưa phân thắng bại thì sẽ thêm bài theo "Quy tắc kéo bài", phát tối đa mỗi bên 1 lá bài phân thắng thua;`,
    "d5": `Bất cứ bên nào giành được 8 hoặc 9 điểm (Người thắng bài chuẩn), ván bài sẽ dùng 4 lá bài đầu để kết toán, không kéo bài nữa;`,
    "d6": `Cuối cùng so chữ số hàng đơn vị của tổng điểm Người Chơi và Nhà Cái.`,
    "d7": `2.Quy tắc kéo bài`,
    "d8": `Sau khi Nhà Cái và Người Chơi đã phát bài vòng đầu, sẽ phán đoán xem có cần kéo bài không, phán đoán bài Người Chơi trước rồi tới Nhà Cái. Chi tiết mời xem bảng dưới đây:`,
    "d9": `Người Chơi kéo bài`,
    "d10": `Tổng điểm 2 lá bài của Người Chơi`,
    "d11": `(Người chơi)`,
    "d12": `Phải kéo bài`,
    "d13": `Không được kéo bài`,
    "d14": `Bài chuẩn, phân thắng thua`,
    "d15": `Nhà Cái kéo bài`,
    "d16": `Tổng điểm 2 lá bài của Nhà Cái`,
    "d18": `(Nhà cái)`,
    "d19": `Phải kéo bài`,
    "d20": `Nếu giá trị lá bài thứ 3 của Người Chơi là 8 điểm, Nhà Cái không được kéo bài`,
    "d21": `Nếu giá trị lá bài thứ 3 của Người Chơi là 0, 1, 8, 9 điểm, Nhà Cái không được kéo bài`,
    "d22": `Nếu giá trị lá bài thứ 3 của Người Chơi là 0, 1, 2, 3, 8, 9 điểm, Nhà Cái không được kéo bài`,
    "d23": `Nếu giá trị lá bài thứ 3 của Người Chơi là 6 hoặc 7 điểm, Nhà Cái phải kéo bài`,
    "d24": `3.Cách tính điểm`,
    "d25": `A-9 điểm, dựa vào số điểm trên lá bài tính điểm, trong đó A là 1 điểm.`,
    "d26": `10 hoặc J hoặc Q hoặc K là 0 điểm.`,
    "d27": `Ví dụ:`,
    "d28": `4.Cửa cược`,
    "d29": `4.1. Nhà cái & Người chơi & Hòa:`,
    "d30": `Nếu điểm Nhà Cái cao hơn Người Chơi, cược "Nhà Cái" thắng;`,
    "d31": `Nhà cái:`,
    "d32": `Người chơi:`,
    "d33": `Nếu điểm Người Chơi cao hơn Nhà Cái, cược "Người Chơi" thắng;`,
    "d34": `Nếu điểm của Nhà Cái, Người Chơi bằng nhau thì người cược Hòa thắng.`,
    "d35": `4.2. Nhà cái đôi & Người chơi đôi:`,
    "d36": `Trong ván hiện tại, 2 lá bài đầu của Nhà Cái hoặc Người Chơi (Chỉ tính số hoặc chữ cái, không tính chất bài) là đôi;`,
    "d37": `Nhà cái/Người chơi:`,
    "d38": `2 lá bài đầu của Nhà Cái là đôi, gọi là Nhà Cái đôi. 2 lá bài đầu của Người Chơi là đôi, gọi là Người Chơi đôi`,
    "d39": `Khi xuất hiện đôi, cược "Nhà Cái đôi" hoặc "Người Chơi đôi" là được trả thưởng với tỷ lệ tương ứng.`,
    "d40": `4.3. Đôi bất kỳ:`,
    "d41": `Là 2 lá bài đầu của Nhà Cái hoặc Người Chơi là đôi (Chi tiết xem Nhà Cái đôi & Người Chơi đôi)`,
    "d42": `Nếu xuất hiện Nhà Cái đôi hoặc Người Chơi đôi, cược "Đôi bất kỳ" thắng.`,
    "d43": `4.4. Đôi hoàn mỹ:`,
    "d44": `Là 2 lá bài đầu của Nhà Cái hoặc Người Chơi là đôi (Chi tiết xem Nhà Cái đôi & Người Chơi đôi), đồng thời thỏa mãn điều kiện chất bài giống nhau;`,
    "d45": `Dù Nhà Cái hay Người Chơi có đôi hoàn mỹ, cược "Đôi hoàn mỹ" đều thắng.`,
    "d46": `4.5. Siêu đôi:`,
    "d47": `Trong số 4 lá bài đầu tiên, nếu có hai hoặc nhiều lá bài cùng giá trị mặt giống nhau (không kể chất).`,
    "d48": `4.6. Rồng 7:`,
    "d49": `Nhà Cái thắng với tổng điểm 3 lá bài là 7.`,
    "d50": `4.7. Gấu Trúc 8:`,
    "d51": `Người Chơi thắng với tổng điểm 3 lá bài là 8.`,
    "d52": `4.8. Hổ Lớn & Hổ Nhỏ:`,
    "d53": `Nhà Cái thắng với tổng điểm 3 lá bài 6 là Hổ lớn;`,
    "d54": `Nhà Cái thắng với tổng điểm 2 lá bài 6 là Hổ nhỏ.`,
    "d55": `4.9. Hổ Hòa:`,
    "d56": `Nhà Cái và Người Chơi hòa với số điểm 6.`,
    "d57": `4.10.Hổ đôi:`,
    "d58": `Căn cứ vào kết quả 4 lá bài đầu của Nhà Cái và Người Chơi, nếu phù hợp với một trong ba loại kết quả dưới đây sẽ giành được tỷ lệ khác nhau:`,
    "d59": `Tính Nhà Cái đôi, Người Chơi đôi và đôi giống hệt nhau；`,
    "d60": `Tính Nhà Cái đôi, Người Chơi đôi；`,
    "d61": `Tính Nhà Cái đôi hoặc Người Chơi đôi；`,
    "d62": `Hòa không hoàn lại tiền cược.`,
    "d63": `4.11. Cái bài chuẩn & Chơi bài chuẩn`,
    "d64": `Nếu hai lá bài đầu tiên của Nhà Cái tổng cộng 8 hoặc 9 điểm và Nhà Cái thắng, những người đặt cược vào "Cái bài chuẩn" sẽ thắng;`,
    "d65": `Nếu hai lá bài đầu tiên của Người Chơi tổng cộng 8 hoặc 9 điểm và Người Chơi thắng, những người đặt cược vào "Chơi bài chuẩn" sẽ thắng.`,
    "d66": `4.12. Bài chuẩn:`,
    "d67": `Tổng điểm 2 lá bài đầu của Nhà Cái hoặc Người Chơi là 8 hoặc 9 là thắng.`,
    "d68": `4.13. Rồng Hổ Hoà:`,
    "d69": `Điểm lá bài đầu tiên của Nhà Cái lớn hơn lá bài đầu tiên của Người Chơi (10, J, Q, K lần lượt là 10, 11, 12, 13 điểm) là Rồng thắng;`,
    "d70": `Điểm lá bài đầu tiên của Người Chơi lớn hơn lá bài đầu tiên của Nhà Cái (10, J, Q, K lần lượt là 10, 11, 12, 13 điểm) là Hổ thắng;`,
    "d71": `Điểm số lá bài đầu tiên của Nhà Cái và Người Chơi bằng nhau (10, J, Q, K lần lượt là 10, 11, 12, 13 điểm) là rồng hổ Hòa.`,
    "d72": `4.14.Super 6:`,
    "d73": `Chế độ không hoa hồng và có hoa hồng đều có thể cược;`,
    "d74": `Khi Nhà Cái chưa kéo bài, số điểm và hàng đơn vị là 6, đồng thời điểm lớn hơn Người Chơi thì tỷ lệ cược là 12;`,
    "d75": `Khi Nhà Cái kéo bài, số điểm và hàng đơn vị là 6, đồng thời điểm lớn hơn Người Chơi thì tỷ lệ cược là 20.`,
    "d76": `4.15. Cái Long Bảo & Chơi Long Bảo :`,
    "d77": `Nhà Cái bài chuẩn thắng thì Nhà Cái Long Bảo thắng;`,
    "d78": `Người Chơi bài chuẩn thắng thì Người Chơi Long Bảo thắng;`,
    "d79": `Nhà Cái không phải bài chuẩn, điểm của Nhà Cái - điểm của Người Chơi = N, nếu N=4~9 thì Nhà Cái Long Bảo thắng;`,
    "d80": `Người Chơi không phải bài chuẩn, điểm của Người Chơi - điểm của Nhà Cái = N, nếu N=4~9 thì Người Chơi Long Bảo thắng;`,
    "d81": `Định nghĩa bài chuẩn: Tổng điểm 2 lá bài đầu là 8 hoặc 9;`,
    "d82": `Định nghĩa không phải bài chuẩn: Tất cả các trường hợp không phải bài chuẩn, chi tiết mời xem bảng tỷ lệ.`,
    "d83": `4.16. Super Hòa:`,
    "d84": `Nhà Cái và Người Chơi hòa nhau, điểm hòa bao gồm "Hòa 1 ~ Hòa 9" có tất cả 10 cấp Super hòa;`,
    "d85": `Ví dụ: Trong trò chơi, khi kết quả mở bài của Nhà Cái, Người Chơi đều là 0 thì cược "Super hòa 0" thắng;`,
    "d86": `5. Bảng tỷ lệ`,
    "d87": `Trò Baccarat có cách chơi Baccarat và Baccarat không hoa hồng, quy tắc hai loại trò chơi như nhau nhưng phương thức trả thưởng có điểm khác nhau:`,
    "d88": `Baccarat`,
    "d89": `Mục đặt cược`,
    "d90": `Tỷ lệ`,
    "d91": `Người Chơi`,
    "d92": `1：1<br> (Hòa hoàn tiền cược)`,
    "d93": `Nhà Cái`,
    "d94": `Có hoa hồng 1：0.95 <br> (Hòa hoàn tiền cược)`,
    "d95": `Không hoa hồng 1：1 <br> (Nếu Nhà Cái 6 điểm thắng thì trả một nửa, hòa hoàn tiền cược)`,
    "d96": `Hòa`,
    "d97": `Người chơi đôi`,
    "d98": `Nhà cái đôi`,
    "d99": `Siêu đôi`,
    "d100": `Đôi Hoàn Mỹ`,
    "d101": `Đôi bất kỳ`,
    "d102": `Rồng 7`,
    "d103": `Gấu Trúc 8`,
    "d104": `Hổ Lớn`,
    "d105": `Hổ Nhỏ`,
    "d106": `Hổ Hòa`,
    "d107": `Hổ đôi`,
    "d108": `Tính Nhà Cái đôi, Người Chơi đôi và đôi giống hệt nhau 1：100 <br>`,
    "d109": `Tính Nhà Cái đôi, Người Chơi đôi 1：20 <br> `,
    "d110": `Tính Nhà Cái đôi hoặc Người Chơi đôi 1：4 <br>`,
    "d111": `Cái bài chuẩn & Chơi bài chuẩn`,
    "d112": `Bài chuẩn`,
    "d113": `Rồng & Hổ`,
    "d114": `Rồng Hổ khi hòa hoàn một nửa tiền cược`,
    "d115": `Rồng Hổ Hòa`,
    "d116": `Super 6`,
    "d117": `Nhà Cái 2 lá bài, 6 điểm thắng, tỷ lệ 12 <br>`,
    "d118": `Nhà Cái 3 lá bài, 6 điểm thắng, tỷ lệ 20 <br>`,
    "d119": `Long Bảo `,
    "d120": `Hòa bài chuẩn，Hoàn tiền cược`,
    "d121": `Hoàn tiền cược`,
    "d122": `Bài chuẩn thắng`,
    "d123": `Bài không chuẩn thắng 4 điểm`,
    "d124": `Bài không chuẩn thắng 5 điểm`,
    "d125": `Bài không chuẩn thắng 6 điểm`,
    "d126": `Bài không chuẩn thắng 7 điểm`,
    "d127": `Bài không chuẩn thắng 8 điểm`,
    "d128": `Bài không chuẩn thắng 9 điểm`,
    "d129": `Super hòa`,
    "d130": `Super hòa (0)`,
    "d131": `Super hòa (1)`,
    "d132": `Super hòa (2)`,
    "d133": `Super hòa (3)`,
    "d134": `Super hòa (4)`,
    "d135": `Super hòa (5)`,
    "d136": `Super hòa (6)`,
    "d137": `Super hòa (7)`,
    "d138": `Super hòa (8)`,
    "d139": `Super hòa (9)`,
    "d140": `6. Đặc điểm trò chơi`,
    "d141": `Dùng 8 bộ bài phổ biến nhất (Bỏ các lá bài Joker tất cả là 416 lá). Quy tắc kéo bài độc nhất, giống như đời người, bất kể thời điểm nào, chỉ cần đầu tư là sẽ có cơ hội đứng vững.`,
    "d142": `7. Những điều cần chú ý`,
    "d143": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "d144": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "d145": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "d146": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "d147": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`,
    "d148": `Quy tắc chơi`,
    "d149": `Quy tắc kéo bài`,
    "d150": `Tính Điểm`,
    "d151": `Cửa cược`,
    "d152": `Bảng tỷ lệ`,
    "d153": `Đặc điểm trò chơi`,
    "d154": `Những điều cần chú ý`
  },
  "DragonTiger": {
    "d1": `Rồng Hổ`,
    "d2": `1.Quy tắc chơi`,
    "d3": `Mỗi ván sử dụng 1 bộ bài (đi vua lớn nhỏ) để chơi;`,
    "d4": `Chỉ phát 2 lần bài, Rồng trước Hổ sau, mỗi bên 1 lá, không cần thêm bài;`,
    "d5": `Sau khi mở bài, so điểm lớn nhỏ gữa hai nhà.
`,
    "d6": `2.Đếm thẻ`,
    "d7": `A tương ứng với 1điểm;`,
    "d8": `Các lá bài từ 2 đến 10, điểm được tính dựa trên số của lá bài`,
    "d9": `J, Q, K lần lượt là 11, 12 và 13 điểm.`,
    "d10": `3.Cửa cược`,
    "d11": `So bài Rồng và Hổ, Rồng lớn hơn thì Rồng thắng, Hổ lớn hơn thì Hổ thắng;`,
    "d12": `Nếu Rồng và Hổ bằng điểm nhau thì Hòa.`,
    "d13": `4.Bảng tỷ lệ`,
    "d14": `Mục đặt cược`,
    "d15": `Tỷ lệ`,
    "d16": `Rồng`,
    "d18": `1:1`,
    "d19": `Nếu hòa ván sẽ trả lại một nửa tiền cược`,
    "d20": `Hổ`,
    "d21": `1:1`,
    "d22": `Nếu hòa ván sẽ trả lại một nửa tiền cược`,
    "d23": `Hòa`,
    "d24": `1:8`,
    "d25": `5.Đặc điểm trò chơi`,
    "d26": `So với Baccarat thì không cần thêm bài, chỉ so 1 lá bài xem lá nào lớn hơn. Quy tắc đơn giản, dễ chơi, trai gái già trẻ đều có thể nhanh chóng trải nghiệm trò chơi.`,
    "d27": `6.Những điều cần chú ý`,
    "d28": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "d29": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "d30": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "d31": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "d32": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`,
    "d33": `Quy tắc chơi`,
    "d34": `Tính Điểm`,
    "d35": `Cửa cược`,
    "d36": `Bảng tỷ lệ`,
    "d37": `Đặc điểm trò chơi`,
    "d38": `Những điều cần chú ý`
  },
  "Bulls": {
    "d1": `Ngưu Ngưu`,
    "d2": `1.Quy tắc chơi`,
    "d3": `Một bộ bài 52 lá (Bỏ các lá Joker);`,
    "d4": `Thứ tự phát bài: Lần lượt là Nhà Cái → Người Chơi 1→ Người Chơi 2→ Người Chơi 3, nhưng cần dùng lá bài đầu quyết định xem phát bài cho ai trước;`,
    "d5": `Ba Người Chơi lần lượt so bài với Nhà Cái, so kiểu bài trước, nếu kiểu bài giống nhau thì so lá bài lớn nhất của mỗi nhà, nếu bài có điểm số bằng nhau thì so chất bài.`,
    "d6": `2.Cách tính điểm`,
    "d7": `Lá bài A tính 1 điểm; lá bài 10, J, Q, K tính 10 điểm; lá bài 2-9 tính theo số điểm tương ứng;`,
    "d8": `Mỗi bên có 5 lá bài, có 3 lá bài cộng lại là bội số của 10 là có Bull, ngược lại là không Bull;`,
    "d9": `Bài có Bull, tổng 2 lá còn lại có hàng đơn vị là 1-9 là Bull 1-9 tương ứng, hàng đơn vị bằng 0 là Bull Bull;`,
    "d10": `Nếu 5 lá bài đều là J/Q/K thì là bài Năm Tây.`,
    "d11": `3.Cửa cược`,
    "d12": `Trong Bull Bull có Nhà Cái, Người Chơi 1, Người Chơi 2, Người Chơi 3;`,
    "d13": `Bàn cược chia thành ba cửa Người Chơi 1, Người Chơi 2, Người Chơi 3, mỗi cửa bao gồm Nhà Cái thường, Nhà Cái gấp bội, Người Chơi thường, Người Chơi gấp bội;`,
    "d14": `Sau khi mở bài, so sánh Nhà Cái và ba Người Chơi. Nhà Cái lớn hơn thì cược Nhà Cái thắng, Người Chơi lớn hơn thì cược Người Chơi thắng;`,
    "d15": `Kiểu bài: Năm Tây > BullBull > Bull 9 > Bull 8 > Bull 7 > Bull 6 > Bull 5 > Bull 4 > Bull 3 > Bull 2 > Bull 1 > Không Bull.`,
    "d16": `lớn nhỏ: K＞Q＞J＞10＞9＞8＞7＞6＞5＞4＞3＞2＞A;`,
    "d18": `Chất: Bích > Cơ > Nhép > Rô;`,
    "d19": `Cược thường, thắng thua bình thường. Cược gấp bội thì thắng thua cũng gấp bội.`,
    "d20": `4.Bảng tỷ lệ`,
    "d21": `Hệ số tỷ lệ cụ thể phụ thuộc vào kiểu bài của người thắng. Cược thường thì thắng thua bình thường, cược gấp bội thì thắng thua cũng gấp bội.`,
    "d22": `Tỷ Lệ Thắng của Người Chơi`,
    "d23": `Kiểu bài bên thắng`,
    "d24": `Thường`,
    "d25": `Gấp bội`,
    "d26": `Năm Tây `,
    "d27": `BullBull`,
    "d28": `Bull`,
    "d29": `Không Bull`,
    "d30": `Tỷ Lệ Thua của Người Chơi`,
    "d31": `Kiểu bài bên thắng`,
    "d32": `Thường`,
    "d33": `Gấp bội`,
    "d34": `Năm Tây `,
    "d35": `BullBull`,
    "d36": `Bull7-Bull9`,
    "d37": `Không Bull - Bull6`,
    "d38": `VD1 về cược và trả thưởng "Gấp bội":`,
    "d39": `Cược`,
    "d40": `Bên 1`,
    "d41": `Cửa cược`,
    "d42": `Người Chơi Gấp bội`,
    "d43": `Tiền cược`,
    "d44": `100, thực tế trừ 500, khi cược hiển thị "100 (Dự kiến trừ 400)"`,
    "d45": `KQ so bài`,
    "d46": `Bài Nhà Cái (B): Bull 8 | Bài Bên 1：Bull 5`,
    "d47": `Trả thưởng`,
    "d48": `Hoàn 300 (Bài bên thắng Bull 8, tỷ lệ thua của người chơi tương ứng là 2, Bên 1 thua 100x2=200)`,
    "d49": `VD2 về cược và trả thưởng "Gấp bội":`,
    "d50": `Cược`,
    "d51": `Bên 1`,
    "d52": `Cửa cược`,
    "d53": `Nhà Cái Gấp bội`,
    "d54": `Tiền cược`,
    "d55": `100, thực tế trừ 500, khi cược hiển thị "100 (Dự kiến trừ 400)"`,
    "d56": `KQ so bài`,
    "d57": `Bài Nhà Cái (B): Bull 8 | Bài Bên 1：Bull 5`,
    "d58": `Trả thưởng`,
    "d59": `690 (Hoàn 500+100*Tỷ lệ thắng của người chơi tương ứng với bài bên thắng Bull 8 1.90=690)`,
    "d60": `5.Đặc điểm trò chơi`,
    "d61": `Là trò chơi bài phổ biến ở khu vực Hồ Quảng. Người chơi có thể tự do đặt cược Nhà Cái và Người Chơi. Cược đặt gấp bội sẽ có nhiều tỷ lệ khác nhau phụ thuộc vào kiểu bài của bên thắng, rất thú vị và hấp dẫn!`,
    "d62": `6.Những điều cần chú ý`,
    "d63": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "d64": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "d65": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "d66": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "d67": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`,
    "d68": `Quy tắc chơi`,
    "d69": `Tính điểm`,
    "d70": `Cửa cược`,
    "d71": `Bảng tỷ lệ`,
    "d72": `Đặc điểm trò chơi`,
    "d73": `Những điều cần chú ý`,
    "d74": `Siêu bội`
  },
  "SanGong": {
    "d1": `Bài 3 tây`,
    "d2": `1.Quy tắc chơi`,
    "d3": `Một bộ bài 52 lá (Bỏ các lá Joker);`,
    "d4": `Thứ tự phát bài: Lần lượt là Nhà Cái → Người Chơi 1→ Người Chơi 2→ Người Chơi 3, nhưng cần dùng lá bài đầu quyết định xem phát bài cho ai trước;`,
    "d5": `Ba Người Chơi và Nhà Cái so bài, Người Chơi lớn hơn thì Người Chơi thắng, Nhà Cái lớn hơn thì Người Chơi thua.`,
    "d6": `2.So Kiểu bài`,
    "d7": `Trong trò chơi, J, Q, K là bài Tây`,
    "d8": `Cửa cược`,
    "d9": `Hướng dẫn (Kiểu bài từ lớn tới nhỏ, xếp từ trên xuống dưới)`,
    "d10": `Bài 3 tây`,
    "d11": `J, Q, K là Tây, 3 lá bài đều được tạo thành từ bài Tây gọi là Bài 3 tây`,
    "d12": `Hai Tây 9`,
    "d13": `2 lá bài Tây, lá bài còn lại 9 điểm`,
    "d14": `Một Tây 9`,
    "d15": `1 lá Ba Tây, tổng điểm 2 lá còn lại có chữ số hàng đơn vị là 9 điểm`,
    "d16": `9 Điểm`,
    "d18": `Tổng điểm 3 lá bài có chữ số hàng đơn vị là 9 điểm`,
    "d19": `Hai Tây 8`,
    "d20": `2 lá bài Tây, lá bài còn lại 8 điểm`,
    "d21": `Một Tây 8`,
    "d22": `1 lá Ba Tây, tổng điểm 2 lá còn lại có chữ số hàng đơn vị là 8 điểm`,
    "d23": `8 Điểm`,
    "d24": `Tổng điểm 3 lá bài có chữ số hàng đơn vị là 8 điểm`,
    "d25": `Hai Tây 7`,
    "d26": `2 lá bài Tây, lá bài còn lại 7 điểm`,
    "d27": `Một Tây 7`,
    "d28": `1 lá Ba Tây, tổng điểm 2 lá còn lại có chữ số hàng đơn vị là 7 điểm`,
    "d29": `7 Điểm`,
    "d30": `Tổng điểm 3 lá bài có chữ số hàng đơn vị là 7 điểm`,
    "d31": `Hai Tây 6`,
    "d32": `2 lá bài Tây, lá bài còn lại 6 điểm`,
    "d33": `Một Tây 6`,
    "d34": `1 lá Ba Tây, tổng điểm 2 lá còn lại có chữ số hàng đơn vị là 6 điểm`,
    "d35": `6 Điểm`,
    "d36": `Tổng điểm 3 lá bài có chữ số hàng đơn vị là 6 điểm`,
    "d37": `Hai Tây 5`,
    "d38": `2 lá bài Tây, lá bài còn lại 5 điểm`,
    "d39": `Một Tây 5`,
    "d40": `1 lá Ba Tây, tổng điểm 2 lá còn lại có chữ số hàng đơn vị là 5 điểm`,
    "d41": `5 Điểm`,
    "d42": `Tổng điểm 3 lá bài có chữ số hàng đơn vị là 5 điểm`,
    "d43": `Hai Tây 4`,
    "d44": `2 lá bài Tây, lá bài còn lại 4 điểm`,
    "d45": `Một Tây 4`,
    "d46": `1 lá Ba Tây, tổng điểm 2 lá còn lại có chữ số hàng đơn vị là 4 điểm`,
    "d47": `4 Điểm`,
    "d48": `Tổng điểm 3 lá bài có chữ số hàng đơn vị là 4 điểm`,
    "d49": `Hai Tây 3`,
    "d50": `2 lá bài Tây, lá bài còn lại 3 điểm`,
    "d51": `Một Tây 3`,
    "d52": `1 lá Ba Tây, tổng điểm 2 lá còn lại có chữ số hàng đơn vị là 3 điểm`,
    "d53": `3 Điểm`,
    "d54": `Tổng điểm 3 lá bài có chữ số hàng đơn vị là 3 điểm`,
    "d55": `Hai Tây 2`,
    "d56": `2 lá bài Tây, lá còn lại 2 điểm`,
    "d57": `Một Tây 2`,
    "d58": `1 lá Ba Tây, tổng điểm 2 lá còn lại có chữ số hàng đơn vị là 2 điểm`,
    "d59": `2 Điểm`,
    "d60": `Tổng điểm 3 lá bài có chữ số hàng đơn vị là 2 điểm`,
    "d61": `Hai Tây 1`,
    "d62": `2 lá bài Tây, lá bài còn lại 1 điểm`,
    "d63": `Một Tây 1`,
    "d64": `1 lá Ba Tây, tổng điểm 2 lá còn lại có chữ số hàng đơn vị là 1 điểm`,
    "d65": `1 Điểm`,
    "d66": `Tổng điểm 3 lá bài có chữ số hàng đơn vị là 1 điểm`,
    "d67": `Hai Tây 0`,
    "d68": `2 lá bài Tây, lá còn lại 0 điểm`,
    "d69": `Một Tây 0`,
    "d70": `1 lá Ba Tây, tổng điểm 2 lá còn lại có chữ số hàng đơn vị là 0 điểm`,
    "d71": `0 Điểm`,
    "d72": `Tổng điểm 3 lá bài có chữ số hàng đơn vị là 0 điểm`,
    "d73": undefined,
    "d74": undefined,
    "d75": undefined,
    "d76": undefined,
    "d77": undefined,
    "d78": undefined,
    "d79": undefined,
    "d80": undefined,
    "d81": undefined,
    "d82": undefined,
    "d83": undefined,
    "d84": `3.Cửa cược`,
    "三公中有庄，闲1，闲2，闲3四家牌；": `Trong trò chơi "Bài 3 tây", có Nhà Cái, Người Chơi 1, Người Chơi 2, Người Chơi 3;`,
    "下注盘则分为闲1，闲2，闲3三门，每门包含庄平倍，庄翻倍，庄超倍，闲平倍，闲翻倍，闲超倍；": `Bàn cược chia thành ba cửa Người Chơi 1, Người Chơi 2, Người Chơi 3, mỗi cửa bao gồm Nhà Cái thường, Nhà Cái gấp bội, Nhà cái Siêu bội, Người Chơi thường, Người Chơi gấp bội và Người chơi Siêu bội;`,
    "开牌后，以庄和三家闲比大小。庄大押庄赢。闲大押闲赢；": `Sau khi mở bài, so sánh Nhà Cái và ba Người Chơi. Nhà Cái lớn hơn thì cược Nhà Cái thắng, Người Chơi lớn hơn thì cược Người Chơi thắng; `,
    "大小：K＞Q＞J＞10＞9＞8＞7＞6＞5＞4＞3＞2＞A；": `lớn nhỏ: K＞Q＞J＞10＞9＞8＞7＞6＞5＞4＞3＞2＞A;`,
    "花色：黑桃＞红心＞梅花＞方块；": `Chất: Bích > Cơ > Nhép > Rô;`,
    "押平倍，输赢平倍。押翻倍，则输赢多倍。押超倍，则输赢多倍。": `Cược thường, thắng thua bình thường. Cược gấp bội thì thắng thua cũng gấp bội. Cược siêu bội thì thắng thua cũng siêu bội.`,
    "d91": `4.Bảng tỷ lệ`,
    "d92": `Tỷ lệ chia thành tỷ lệ thắng thua của Nhà Cái và Người Chơi, và tỷ lệ kiểu bài đặc biệt.`,
    "d93": `Kết quả mở bài`,
    "d94": `Tỷ lệ`,
    "d95": `Người chơi (thắng)`,
    "d96": `1:1`,
    "d97": `Người chơi (thua)`,
    "d98": `1:1`,
    "d99": `Hòa`,
    "d100": `Bài 3 tây`,
    "d101": `Kiểu bài`,
    "d102": `Tỷ lệ`,
    "d103": undefined,
    "d104": undefined,
    "d105": undefined,
    "d106": undefined,
    "d107": undefined,
    "d108": `Nếu kiểu bài cùng lúc thỏa mãn nhiều kết quả thì lấy kết quả tỷ lệ cao làm chuẩn;`,
    "d109": `Ví dụ ♠️2 ♠️3 ♠️4, cùng lúc thỏa mãn Thùng phá sảnh và Sảnh, Thùng phá sảnh tỷ lệ cao hơn nên trả thưởng theo tỷ lệ của Thùng phá sảnh.`,
    "d110": `Trò chơi không có phí tổ chức nhưng hai trường hợp dưới đây có tỷ lệ đặc biệt:`,
    "d111": `Cược thắng, Người Chơi thắng với kiểu bài bất kỳ, số điểm 6 thì giành được tỷ lệ cược 1 trả 0.5;`,
    "d112": `
Cược thua, Nhà Cái thắng với kiểu bài bất kỳ, số điểm 6 thì giành được tỷ lệ cược 1 trả 0.5.`,
    "d113": `5.Đặc điểm trò chơi`,
    "d114": `Bài Bài 3 tây là trò chơi dân gian truyền thống, đặt cược đơn giản, không cần thao tác và tính toán phức tạp, chủ yếu dựa vào may rủi, rất được hoan nghênh trong số các loại trò chơi mới nổi.`,
    "d115": `6.Những điều cần chú ý`,
    "d116": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "d117": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "d118": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "d119": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "d120": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`,
    "d121": `Quy tắc chơi`,
    "d122": `So Kiểu bài`,
    "d123": `Kiểu bài đôi trở lên`,
    "d124": `Cửa cược`,
    "d125": `Bảng tỷ lệ`,
    "d126": `Đặc điểm trò chơi`,
    "d127": `Những điều cần chú ý`,
    "玩家赢赔率": `Tỷ Lệ Thắng của Người Chơi`,
    "玩家输赔率": `Tỷ Lệ Thua của Người Chơi`,
    "胜方牌型": `Kiểu bài bên thắng`,
    "平倍": `Thường`,
    "翻倍": `Gấp bội`,
    "超倍": `Siêu bội`,
    "三条": `Sám`,
    "3条3": `3 lá 3`,
    "点": `Điểm`
  },
  "GoldenFlower": {
    "龙凤炸金花": `R/P Nổ kim Hoa`,
    "游戏规则": `Quy tắc chơi`,
    "使用一副牌游戏（去大小王）进行游戏；": `Sử dụng một bộ bài (Bỏ các lá Joker) để chơi;`,
    "先比较牌型大小，若牌型一致再按从大到小的顺序依次比较牌面大小，决定胜负。若龙、凤的三张牌大小一致，则为和局，退回玩家在本局下注龙、凤的筹码。": `So kiểu bài trước, nếu kiểu bài như nhau thì so sánh điểm số theo thứ tự từ lớn đến nhỏ (Không tính chất bài) để quyết định thắng thua. Nếu điểm số 3 lá bài của rồng, phượng bằng nhau thì hòa, hoàn lại tất cả tiền cược rồng, phượng của người chơi trong ván này.


`,
    "玩法判定": `Cửa cược`,
    "牌型": `Kiểu bài`,
    "豹子杀手：": `Sát thủ bão:`,
    "三张非同花的2，3，5；": `Ba lá 2, 3, 5 không cùng chất;`,
    "豹子：": `Bão:`,
    "三张点数相同的牌；": `Ba lá bài cùng điểm số;`,
    "同花顺：": `Thùng Phá sảnh:`,
    "花色相同的顺子牌；": `Bài theo thứ tự cùng chất;`,
    "同花：": `Thùng:`,
    "花色相同的任意三张牌；": `Ba lá bài tùy ý có cùng chất;`,
    "顺子：": `Sảnh:`,
    "三张连续的牌；": `Ba lá bài theo thứ tự;`,
    "对子：": `Đôi:`,
    "两张点数相同的牌；": `Hai lá bài cùng điểm số;`,
    "散牌：": `Bài Lẻ:`,
    "无法组成任何类型的牌；": `Bài không thể tạo thành kiểu bài gì.`,
    "牌型大小": `So Kiểu bài`,
    "豹子＞同花顺＞同花＞顺子＞对子＞散牌；": `Bão > Thùng Phá sảnh > Thùng > Sảnh > Đôi > Bài Lẻ;`,
    "豹子杀手＞豹子；": `Sát thủ bão > Bão;`,
    "当豹子牌和豹子杀手牌同时存在时，豹子杀手牌比豹子牌大；": `Nếu bài bão và bài sát thủ bão cùng xuất hiện thì bài sát thủ bão lớn hơn;`,
    "散牌＞豹子杀手；": `Bài Lẻ > Sát thủ bão;`,
    "如不存在豹子牌，豹子杀手是最小的散牌牌型。": `Nếu không có bài bão thì bài sát thủ bão là kiểu bài lẻ nhỏ nhất.`,
    "对子大小": `Đôi lớn nhỏ`,
    "如果两家都是对子牌，即比对对子点数大小，对A＞对K＞对Q＞对J＞对10＞对9＞对8＞对7＞对6＞对5＞对4＞对3＞对2；": `Nếu hai nhà đều là bài đôi thì sẽ so sánh điểm số của các đôi, Đôi A＞Đôi K＞Đôi Q＞Đôi J＞Đôi 10＞Đôi 9＞Đôi 8＞Đôi 7＞Đôi 6＞Đôi 5＞Đôi 4＞Đôi 3＞Đôi 2;`,
    "两对对子的点数相同，再比单牌点数大小。": `Nếu điểm của hai đôi như nhau thì so điểm số của lá bài riêng lẻ.`,
    "豹子、同花、散牌牌型": `Kiểu bài bão, thùng, bài lẻ`,
    "比最大那张牌的点数，如最大的那张点数相同，则比第二大牌的点数，以此类推；A>K>Q>J>10>9>8>7>6>5>4>3>2，花色不分大小。": `So điểm của lá bài lớn nhất, nếu lá lớn nhất bằng điểm nhau thì so điểm của lá lớn thứ hai, tương tự như vậy; A>K>Q>J>10>9>8>7>6>5>4>3>2, chất bài không phân lớn nhỏ.`,
    "5. 同花顺、顺子": `Thùng Phá Sảnh, Sảnh`,
    "按照顺序比点数：AKQ>KQJ>......>432>32A": `So điểm theo thứ tự: AKQ>KQJ>......>432>32A`,
    "注意：只有在同花顺和顺子牌型中，A可作为最大或最小的牌，其余牌型中A为最大的单牌": `Lưu ý: Chỉ trong Thùng Phá Sảnh và Sảnh, A mới được xem là quân bài lớn nhất hoặc nhỏ nhất; trong các loại bài khác, A là quân bài đơn lớn nhất`,
    "赔率表": `Bảng tỷ lệ`,
    "投注项目": `Mục đặt cược`,
    "规则": `Quy tắc`,
    "赔率": `Tỷ lệ`,
    "龙": `Rồng`,
    "龙胜出": `Rồng thắng`,
    "凤": `Phượng`,
    "凤胜出": `Phượng Thắng`,
    "对8以上": `Đôi 8 Trở Lên`,
    "龙或凤以对8以上的牌型胜出": `Bài Rồng hoặc Phượng thắng bằng đôi 8 trở lên`,
    "顺子": `Sảnh`,
    "龙或凤以顺子的牌型胜出": `Rồng hoặc Phượng thắng bằng bài sảnh`,
    "同花": `Thùng`,
    "龙或凤以同花的牌型胜出": `Rồng hoặc Phượng thắng bằng bài thùng`,
    "同花顺": `Thùng Phá sảnh`,
    "龙或凤以同花顺的牌型胜出": `Rồng hoặc Phượng thắng bằng bài thùng phá sảnh`,
    "豹子": `Bão`,
    "龙或凤以豹子的牌型胜出": `Rồng hoặc Phượng thắng bằng bài bão`,
    "游戏特点": `Đặc điểm trò chơi`,
    "龙凤炸金花，也被称为赢三张。是一种广泛流传的民间游戏。最大特色除常规押注龙，凤。也可对赢家牌型进行投注。甚至任何对8以上牌型都为赢的玩法。可谓由简至繁，满足各阶层玩家。": `Rồng Phượng nổ kim hoa, còn được gọi là Thắng Ba Lá, là trò chơi dân gian được lưu truyền rộng rãi.. Một đặc điểm nổi bật của trò chơi này, ngoài việc đặt cược vào "Rồng" và "Phượng" như thường lệ, là người chơi cũng có thể đặt cược vào tay bài chiến thắng. Ngoài ra, bất kỳ tay bài nào trên đôi tám đều được coi là tay bài chiến thắng. Lối chơi này trải dài từ đơn giản đến phức tạp, phục vụ cho người chơi ở mọi cấp độ.`,
    "注意事项": `Những điều cần chú ý`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "如遇结算错误，按照当期视频结果重新结算。": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`
  },
  "TexasHoldem": {
    "德州扑克": `Hold'em`,
    "游戏规则": `Quy tắc chơi`,
    "采用1副牌（去大小王），共计52张；": `Dùng 1 bộ bài (bỏ joker), tổng 52 lá`,
    "首先发出5张公共牌，然后发给闲家2张手牌，庄家2张手牌；": `Đầu tiên phát 5 lá bài chung, sau đó phát cho Player 2 lá, Banker 2 lá;`,
    "投注倒计时结束后，荷官会首先翻开闲家2张手牌和前3张公共牌；": `Sau khi đếm ngược kết thúc, dealer sẽ mở 2 lá bài của Player và 3 lá bài chung đầu tiên`,
    "然后进入跟注阶段，闲家需要决定是否跟注；如果选择跟注，则需要在跟注区域投注相当于底注2倍的筹码。如果不跟注，则放弃本局，输掉底注。": `Sau đó bước vào giai đoạn theo cược, Player phải quyết định có cược tiếp hay không; nếu theo, thì phải theo cược số tiền tương đương 2 lần số chip đã cược lúc đầu. Nếu không theo, tức là dừng chơi ván này, thua số tiền đã cược.`,
    "跟注环节结束后，荷官翻开后2张公共牌；": `Sau vòng theo cược, dealer mở 2 lá bài chung sau cùng`,
    "玩家通过自己的2张手牌和5张公共牌组成最大的5张牌，跟庄家比大小。": `Người chơi thông qua 5 lá bài lớn nhất trong tổ hợp 2 lá bài của mình và 5 lá bài chung để thi đấu với Banker.`,
    "AA边注玩法": `Cược bên AA`,
    "AA边注是可选的。可用于投注最先发的5张牌（玩家的两张牌和前三张公共牌）有没有一对A或更好的牌。": `Có thể chọn cược bên AA. Dùng để cược 5 lá bài phát đầu tiên (2 lá bài tẩy của người chơi và 3 lá bài chung đầu tiên) có đôi A hoặc bài đẹp hơn không.`,
    "玩法判定": `Cửa cược`,
    "牌型": `Kiểu bài`,
    "皇家同花顺": `Sảnh Chúa`,
    "皇家同花顺是指包括A、K、Q、J、10这样大牌的同花顺。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `Sảnh Chúa: 5 lá đồng chất A, K, Q, J, 10. Nếu trò chơi hòa, người chơi lấy lại tiền cược ban đầu (Cược gốc và theo).`,
    "同花顺": `Thùng phá sảnh`,
    "同花顺是指五张连续数值的同花牌，比如：9、8、7、6、5，而且全部是红心。两个同花顺相比，大牌面的胜出。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `Thùng Phá Sảnh: 5 lá điểm số liên tiếp và đồng chất, VD: 9, 8, 7, 6, 5, và đều là chất Cơ.So sánh 2 bài Thùng Phá Sảnh, bài điểm lớn hơn thắng. Nếu trò chơi hòa, người chơi lấy lại tiền cược ban đầu (Cược gốc và theo).`,
    "注：同花A、2、3、4、5为最小的同花顺；同花J、Q、K、A、2/Q、K、A、2、3/K、1、2、3、4不为同花顺。": `Lưu ý: Thùng A, 2, 3, 4, 5 là Thùng Phá Sảnh nhỏ nhất; Thùng J, Q, K, A, 2 / Q, K, A, 2, 3 / K, 1, 2, 3, 4 không phải Thùng Phá Sảnh.`,
    "四条": `Tứ quý`,
    "四条是指一手牌中包含四种花色同一数值的牌，再加上一张其他牌。比如说，您拿到了四种花色的A，那就是四条。大牌值的四条大于小牌值的四条。": `Tứ Quý: Bài có 4 lá 4 loại chất cùng điểm số và 1 lá bài lẻ. Ví dụ, 4 lá A là Tứ quý. Tứ Quý điểm số lớn lớn hơn tứ quý điểm số nhỏ.`,
    "葫芦": `Cù lũ`,
    "葫芦是指一手牌中有三种花色的同值牌，还有两种花色的另一同值牌，例如三张K和两张6。在两手葫芦牌中，三张同值牌面较大的一家胜出。": `Cù Lũ: Bài có 3 lá 3 loại chất cùng điểm số và 2 lá 2 loại chất cùng điểm số khác, ví dụ 3 lá K và 2 lá 6. Trong 2 bài Cù Lũ, bài có 3 lá bài cùng điểm số lớn hơn thắng.`,
    "同花": `Thùng`,
    "同花是指一手牌中的所有牌都是同一花色，但不是连续的，例如五张梅花。在两手同花中，根据每手牌中最大牌面的大小决定输赢。如果两手牌中最大牌面大小相同，那么以第二大牌面的大小决定输赢，以此类推直到分出输赢。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `Thùng: 5 lá đồng chất nhưng điểm số không liên tiếp, ví dụ 5 lá Nhép. 2 bài cùng chất phân thắng thua bằng cách so lá lớn nhất. Nếu lá lớn nhất bằng nhau thì so lá lớn thứ 2, tương tự như vậy cho đến khi phân thắng thua. Nếu trò chơi hòa, người chơi lấy lại tiền cược ban đầu (Cược gốc và theo).`,
    "顺子": `Sảnh`,
    "顺子是指一手牌中五张牌都是连续的，但不是同种花色，例如非同花9、8、7、6、5。两手顺子相比，大牌面的一手胜出。如果两手顺子牌面值相同，花色不作为决定输赢的条件；如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `Sảnh: 5 lá điểm số liên tiếp nhưng khác chất, ví dụ 9, 8, 7, 6, 5 khác chất. So 2 bài Sảnh, bên có điểm số lớn hơn thắng. Nếu 2 bài Sảnh điểm số bằng nhau, chất bài không được dùng để phân thắng thua; người chơi lấy lại tiền cược ban đầu (Cược gốc và theo), trò chơi hòa.`,
    "注：A、2、3、4、5为最小的顺子；J、Q、K、A、2/Q、K、A、2、3/K、1、2、3、4不为顺子。": `Lưu ý: A, 2, 3, 4, 5 là Sảnh nhỏ nhất; J, Q, K, A, 2 / Q, K, A, 2, 3 / K, 1, 2, 3, 4 không phải là Sảnh.`,
    "三条": `Sám`,
    "三条是指一手牌中包含三种花色的同值牌，还有两张非同值牌。比如说，如果玩家拿到了三条K，那就称之为三条。大牌面的三条胜出小牌面的三条。": `Sám: 3 lá 3 chất cùng điểm số và 2 lá khác điểm số. Ví dụ người chơi giành được 3 lá K là Sám. Sám bài lớn thắng Sám bài nhỏ.`,
    "两对": `Hai đôi`,
    "两对以下": `Dưới hai đôi`,
    "两对是指一手牌中包含两张同值牌，还有两张另一牌值的同值牌（牌面相等，但是与第一个对子牌面不相等），还有一张其他牌面的牌。一手牌中包含两张A和两张K就是两对。对于两手两对牌，比较各手牌中牌面较大的对子，大牌面的一手胜出。如果两手牌中较大牌面对子大小相同，则比较第二个对子。如果两个对子大小均相同看，则根据另外一张单牌决定输赢。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `Hai Đôi: 2 lá bài cùng điểm số và 2 lá bài cùng điểm số khác (Bài cùng điểm số nhưng khác đôi đầu tiên), và có 1 lá bài điểm số khác. Bài trong tay gồm 2 lá A và 2 lá K là 2 đôi. Nếu 2 bài cùng có 2 đôi, sẽ so đôi lớn, bên có đôi lớn hơn thắng. Nếu đôi lớn bằng nhau thì so đôi thứ 2. Nếu đôi thứ 2 bằng nhau, sẽ phân thắng thua bằng lá bài lẻ. Nếu trò chơi hòa, người chơi lấy lại tiền cược ban đầu (Cược gốc và theo).`,
    "一对": `Đôi`,
    "对子是指一手牌中包含两张同值牌（例如两张K），再加上三张其它非同值牌。牌值较大的对子胜过较小的对子。如果两手牌对子的牌面大小相同，则降序比较其它三张牌。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `Một Đôi: Bài có 2 lá cùng điểm số (Ví dụ 2 lá K), và 3 lá khác không cùng điểm số. Đôi điểm số lớn thắng đôi điểm số nhỏ. Nếu đôi của 2 bài bằng nhau, so 3 lá khác. Nếu trò chơi hòa, người chơi lấy lại tiền cược ban đầu (Cược gốc và theo).`,
    "高牌": `Mậu thầu`,
    "高牌是指不属于上述任何一种牌型的一手牌。不属于任何牌型。": `Mậu thầu: Bài không thuộc loại bài nào kể trên, không thuộc bất cứ loại bài nào.`,
    "牌型大小": `So kiểu bài`,
    "皇家同花顺＞同花顺＞四条＞葫芦＞同花＞顺子＞三条＞两对＞一对＞高牌": `Sảnh Chúa > Thùng phá sảnh > Tứ quý > Cù lũ > Thùng > Sảnh > Sám > Hai đôi > Một đôi > Mậu thầu`,
    "庄家最后的牌型必须为对4或以上，才有资格与闲家牌型比对；否则视为闲家赢。": `Bài Nhà Cái là đôi 4 hoặc đôi 4 trở lên mới được so bài với Người Chơi, nếu không Người Chơi thắng.`,
    "输赢判定和赔率表": `Phán đoán thắng thua và bảng tỷ lệ cược
`,
    "底注、跟注玩法赔率（不含本金）如下：": `Bảng tỷ lệ cược cửa cược cược gốc, theo (Không gồm tiền cược):`,
    "输赢": `Thắng thua`,
    "底注": `Cược gốc`,
    "跟注": `Theo cược`,
    "庄家没有资格(对4或以下),玩家胜": `Nhà cái cá cược không đủ điều kiện (cặp 4 hoặc ít hơn), người chơi thắng`,
    "赔付表": `Bảng Thanh Toán`,
    "返回本金": `Hoàn tiền cược`,
    "庄家有资格(对4或以上),玩家胜": `Nhà Cái có tư cách (Đôi 4 hoặc đôi 4 trở lên),người chơi thắng`,
    "庄家有资格(对4或以上),平局": `Nhà Cái có tư cách (Đôi 4 hoặc đôi 4 trở lên),Hòa`,
    "玩家不跟注(弃牌)": `Người chơi không theo cược (Bỏ bài)`,
    "输": `Thua`,
    "庄家有资格(对4或以上),庄家胜": `Nhà Cái có tư cách (Đôi 4 hoặc đôi 4 trở lên),Nhà Cái thắng`,
    "底注玩法赔率（不含本金）如下：": `Bảng trả thưởng cửa cược cược gốc (Không gồm tiền cược):`,
    "手牌": `Bài`,
    "赔率": `Tỷ lệ`,
    "AA边注玩法赔率（不含本金）如下：": `Tỷ lệ cược cửa cược bên AA (Không gồm tiền cược):`,
    "注意事项": `Những điều cần chú ý`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "如遇结算错误，按照当期视频结果重新结算。": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`
  },
  "SicBo": {
    "骰宝": `SicBo`,
    "游戏规则": `Quy tắc chơi`,
    "荷官启动开关，机器产生3次或3次以上震动，透明骰蛊中三颗6面骰子将在震动作用下弹跳；": `Dealer kích hoạt công tắc, máy sẽ rung 3 lần trở lên, lắc ba viên xúc xắc 6 mặt trong hộp lắc xúc xắc trong suốt;`,
    "当停止震动，骰子落下时，取其上面显现的点数计数；": `Khi hộp ngừng rung, xúc xắc rơi xuống, tính điểm hiển thị trên mặt xúc xắc;`,
    "和三颗骰子所表现点数，组合，或和值相符合的玩法胜出；": `Cược đặt giống với điểm số, tổ hợp hoặc tổng điểm của ba viên xúc xắc thắng;`,
    "如震动停止后，三颗骰子有任意颗底面没有完全接触骰蛊最底面，或出现重叠状态，或震动次数少于3次，将会再次触发震动重新获取结果。": `Nếu sau khi ngừng rung, ba viên xúc xắc có bất kỳ viên nào có mặt xúc xắc không hoàn toàn tiếp xúc với đáy hộp lắc, hoặc xuất hiện trạng thái trùng lặp hoặc rung ít hơn3 lần, hệ thống sẽ kích hoạt lại rung để lấy kết quả mới.`,
    "玩法判定": `Cửa cược`,
    "大/小": `Tài/Xỉu`,
    "三颗骰子总点数11-17为大，4-10为小；": `Tổng điểm 3 viên xúc xắc là 11-17 là tài, 4-10 là xỉu;`,
    "出现围骰，庄家通吃，大/小均输。": `Nếu xuất hiện Tam bảo, Nhà Cái thắng hết, tài/xỉu đều thua.`,
    "单/双": `Lẻ/Chẵn`,
    "三颗骰子和值，分为单双两种情况；": `Tổng điểm ba viên xúc xắc chia thành hai trường hợp lẻ chẵn;`,
    "总点数为5，7，9，11，13，15，17为单；": `Tổng điểm 5, 7, 9, 11, 13, 15 và 17 được coi là Lẻ;`,
    "总点数为4，6，8，10，12，14，16为双；": `Tổng điểm 4, 6, 8, 10, 12, 14 và 16 được coi là Chẵn;`,
    "出现围骰，庄家通吃，单/双均输。": `Xuất hiện Tam bảo, Nhà Cái thắng hết, lẻ/chẵn đều thua.`,
    "单点": `Đơn điểm`,
    "根据所选择点数相符的骰子出现次数判断；": `Phán đoán dựa trên số lần xuất hiện của xúc xắc phù hợp với điểm số đã chọn;
`,
    "有一个骰子符合，则为单骰；": `Có 1 xúc xắc phù hợp là 1 viên;`,
    "有两个骰子符合，则为双骰；": `Có 2 xúc xắc phù hợp là 2 viên;`,
    "有三个骰子符合，则为三骰。": `Có 3 xúc xắc phù hợp là 3 viên.`,
    "和值": `Tổng điểm`,
    "三个骰子的点数之和为和值；": `Tổng số điểm của 3 viên xúc xắc là tổng điểm;`,
    "和值范围为4-17点。不同和值具有不同赔率（详见赔率）。": `Phạm vi tổng điểm: 4-17 điểm. Tổng điểm khác nhau có tỷ lệ khác nhau (Chi tiết mời xem bảng tỷ lệ).`,
    "牌九式": `Kết hợp`,
    "三颗骰子中，任意两颗骰子满足特定组合条件；": `Trong 3 viên xúc xắc, có bất cứ 2 viên xúc xắc nào thỏa mãn yêu cầu tổ hợp nhất định;`,
    "总共15种开出的三颗骰子任意两个满足即可胜出。": `3 viên xúc xắc có tất cả 15 loại kết quả, chỉ cần 2 viên xúc xắc bất kỳ thỏa mãn yêu cầu là thắng.`,
    "对子": `Đôi`,
    "三颗骰子中，任意两颗骰子点数相同即为对子；": `2 trong số 3 viên xúc xắc có điểm số như nhau là đôi;`,
    "在6种对子玩法中押中，即可胜出。": `Cược trúng 6 cửa cược đôi là thắng.`,
    "全围": `Tam bảo bất kỳ`,
    "三颗骰子点数相同即全围；": `Điểm 3 xúc xắc bất kỳ như nhau là tam bảo bất kỳ;`,
    "包含6种围骰组合，出现任意一种即可胜出。": `Bao gồm 6 loại tổ hợp tam bảo bất kỳ, xuất hiện 1 trong số đó là thắng.`,
    "围骰": `Tam bảo`,
    "投注特定的一种围骰，即三颗骰子点数完全一样。满足条件即可胜出。": `Đặt cược một loại tam bảo nhất định, nghĩa là điểm của 3 viên xúc xắc y hệt nhau, thỏa mãn điều kiện là thắng.`,
    "赔率表": `Bảng tỷ lệ`,
    "玩法赔率": `Tỷ lệ cửa cược`,
    "投注项目": `Mục đặt cược`,
    "赔率": `Tỷ lệ`,
    "大": `Tài`,
    "小": `Xỉu`,
    "单骰": `1 viên`,
    "双骰": `2 viên`,
    "三骰": `3 viên`,
    "对子（双骰、长牌）": `Đôi (2 viên)`,
    "牌九式（骨牌、短牌）": `Kết Hợp (Domino, Lá Bài Ngắn)`,
    "点数和为4或17": `Tổng là 4 hoặc 17`,
    "点数和为5或16": `Tổng là 5 hoặc 16`,
    "点数和为6或15": `Tổng là 6 hoặc 15`,
    "点数和为7或14": `Tổng là 7 hoặc 14`,
    "点数和为8或13": `Tổng là 8 hoặc 13`,
    "点数和为9、10、11或12": `Tổng là 9, 10, 11 hoặc 12`,
    "单": `Lẻ`,
    "双": `Chẵn`,
    "游戏特点": `Đặc điểm trò chơi`,
    "是古老的中国骰子游戏，玩家通过猜测骰子开出的点数，总和，组合等，具备多元化投注种类，以及极具吸引力的赔率，极受中国乃至亚洲玩家欢迎。": `Là trò chơi xúc xắc cổ của Trung Quốc, người chơi đoán điểm số, tổng điểm, tổ hợp... của xúc xắc, loại hình đặt cược đa dạng và tỷ lệ cược hấp dẫn nên rất được người chơi Trung Quốc, thậm chí là châu Á yêu thích.`,
    "注意事项": `Những điều cần chú ý`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "如遇结算错误，按照当期视频结果重新结算。": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`
  },
  "SeDie": {
    "色碟": `Xóc đĩa`,
    "游戏规则": `Quy tắc chơi`,
    "荷官将4颗红白双色的钮扣放入碟中，盖上碗后上下摇碟；完成摇碟动作后静置于画面中供会员下注。下注时间结束将碗打开，依开出结果派彩。": `Dealer đặt 4 nút đỏ trắng vào đĩa, đậy bát và xóc, sau khi xóc xong sẽ để yên trước màn hình để người chơi đặt cược. Khi thời gian cược kết thúc, mở bát và trả thưởng theo kết quả.`,
    "玩法说明": `Cách chơi`,
    "大：开出(4个红色)、(3个红色，1个白色钮扣)属「大」。二红二白视为和局，退回玩家下注筹码": `Tài: Mở (4 nút đỏ), (3 nút đỏ, 1 nút trắng) là [Tài]. 2 đỏ và 2 trắng là hòa, hoàn tiền cược của người chơi.`,
    "小：开出(4个白色)、(3个白色，1个红色钮扣)属「小」。二红二白视为平手，退回玩家下注筹码": `Xỉu: Mở (4 nút trắng), (3 nút trắng, 1 nút đỏ) là [Xỉu]. 2 đỏ và 2 trắng là hòa, hoàn tiền cược của người chơi.`,
    "单：开出(3个红色，1个白色钮扣)、(3个白色，1个红色钮扣)属「单」；": `  
         
Lẻ: Mở (3 nút đỏ, 1 nút trắng), (3 nút trắng, 1 nút đỏ) là [Lẻ];`,
    "双：开出(4个红色钮扣)、(4个白色钮扣)、(2个白色、2个红色钮扣)属「双」；": ` 
         
Chẵn: Mở (4 nút đỏ), (4 nút trắng), (2 nút trắng, 2 nút đỏ) là [Chẵn];`,
    "4（4红）：开出4个红色钮扣。": `4 (4 Đỏ): Mở 4 nút đỏ.`,
    "0（4白)：开出4个白色钮扣。": `0 (4 Trắng): Mở 4 nút trắng.`,
    "3(3红1白)：开出3个红色，1个白色钮扣。": `3 (3 Đỏ 1 Trắng): Mở 3 nút đỏ, 1 nút trắng.`,
    "1(3白1红)：开出3个白色，1个红色钮扣。": `1 (3 Trắng 1 Đỏ): Mở 3 nút trắng, 1 nút đỏ.`,
    "赔率表": `Bảng tỷ lệ`,
    "玩法赔率": `Tỷ lệ cửa cược`,
    "下注组合": `Tổ hợp cược`,
    "赔率": `Tỷ lệ`,
    "大": `Tài`,
    "小": `Xỉu`,
    "单": `Lẻ`,
    "双": `Chẵn`,
    "4(4红)": `4 (4 Đỏ)`,
    "0(4白)": `0 (4 Trắng)`,
    "3(3红1白)": `3 (3 Đỏ, 1 Trắng)`,
    "1(3白1红)": `1 (3 Trắng, 1 Đỏ)`,
    "特别说明": `Đặc biệt lưu ý`,
    "本游戏进行方式为最接近实境赌场之设置，若有发生特殊情形将依本公司公告之办法处理。": ` Xóc đĩa giống với chơi ở sòng bạc thực tế nhất, nếu có tình huống đặc biệt sẽ xử lý theo cách công ty thông báo.`,
    "荷官在游戏进行中若不慎让碗、碟或钮扣掉落，将物件放回定位后，重新进行摇碟。": `Trong khi chơi, nếu Dealer không may làm rơi bát, đĩa hoặc nút, sẽ đặt chúng về vị trí cũ và xóc lại đĩa.`,
    "若开出结果时有钮扣重叠无法判定结果时，荷官将用玻璃棒分开重叠的钮扣。": `Nếu khi mở nút chồng chéo lên nhau không xác định được kết quả, Dealer sẽ dùng gậy thủy tinh để tách các nút chồng nhau ra.`,
    "荷官若没有摇碟就开碟，或在下注时间尚未结束前即开碟，该局将判定为无效，并退还所有下注本金。": `Nếu Dealer chưa xóc đĩa đã mở đĩa, hoặc thời gian cược chưa kết thúc đã mở đĩa thì ván đó được xem như không hợp lệ và sẽ hoàn trả toàn bộ tiền cược.`,
    "荷官摇碟后开碟过程:如出现【开碟中的碗触碰到钮扣】，最终以开碟后钮扣倒下的颜色为最终结果。": `Dealer mở đĩa sau khi đã xóc đĩa: Nếu【Bát đang mở đĩa chạm vào nút】, kết quả cuối cùng là màu nút rơi xuống sau khi mở đĩa.`,
    "注意事项": `Những điều cần chú ý`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "如遇结算错误，按照当期视频结果重新结算。": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`
  },
  "AndarBahar": {
    "安达巴哈": `Andar Bahar`,
    "游戏规则": `Quy tắc chơi`,
    "安达巴哈是一款非常简单的纸牌游戏，该游戏使用1副牌（去大小王）；": `Andar Bahar là một game bài rất đơn giản, sử dụng 1 bộ bài (trừ 2 lá Joker);`,
    "荷官在小丑牌位置上发一张牌，牌面朝上，然后继续在A（安达）和B（巴哈）点上发牌，牌面朝上；": `Dealer đặt một lá bài ở vị trí Joker, đặt nằm ngửa, sau đó tiếp tục chia bài vào tụ A (Andar) và B (Bahar), đặt nằm ngửa;`,
    "出现一张牌和小丑牌的面值相同时，游戏结束。": `Khi xuất hiện 1 lá bài cùng giá trị với Joker, trò chơi sẽ kết thúc.`,
    "玩法判定": `Cửa cược`,
    "安达或巴哈哪家先发出和小丑牌面值相同时，哪家获胜。": `Andar và Bahar bên nào được phát lá bài tương đương với Joker trước thì bên đó thắng.`,
    "赔率表": `Bảng tỷ lệ`,
    "玩法赔率": `Tỷ lệ cửa cược`,
    "投注项目": `Mục đặt cược`,
    "赔率": `Tỷ lệ`,
    "安达": `Andar`,
    "巴哈": `Bahar`,
    "游戏特点": `Đặc điểm trò chơi`,
    "安达巴哈是印度最受欢迎的纸牌游戏之一，它的流行在于它的简单性，您只有两种玩法选择，投注简单快速。": `Andar Bahar là một trong những trò chơi bài phổ biến nhất ở Ấn Độ, sự phổ biến của nó là do sự đơn giản, chỉ có 2 cách chơi, đặt cược dễ dàng và nhanh chóng.`,
    "注意事项": `Những điều cần chú ý`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "如遇结算错误，按照当期视频结果重新结算。": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`
  },
  "Blackjack": {
    "21点": `Blackjack`,
    "游戏简述": `Giới thiệu`,
    "21点又名黑杰克（Blackjack），起源于法国，已流传到世界各地，有着悠久的历史。该游戏使用除大小王之外的52张牌，游戏者的目标是使手中的牌的点数之和不超过21点且尽量大，然后和荷官比大小。": `Blackjack còn gọi là 21, bắt nguồn từ Pháp, phổ biến trên toàn thế giới, có lịch sử lâu đời. Trò chơi dùng 52 lá bài (Trừ lá Joker), tổng điểm các lá bài trong tay lớn nhất và không quá 21 điểm, sau đó so bài với Dealer.`,
    "玩法规则": `Quy tắc chơi`,
    "游戏规则": `Quy tắc chơi`,
    "7个主玩家坐在赌桌上（可有无限数量的旁注玩家）。21点的目标是让您的牌比庄家的牌更接近21点，但不能超过21点。在21点中，A可以算作1，也可以算作11，花牌（J、Q、K）算10，数字牌按照面值算。Blackjack比21点大。": `7 người chơi chính ngồi tại bàn cược (Không giới hạn người chơi cược phụ). Mục tiêu của Blackjack là để bài của bạn gần 21 điểm hơn Nhà Cái, nhưng không được quá 21 điểm. Trong Blackjack, A có thể coi là 1 hoặc 11, J, Q, K là 10, bài số tính theo giá trị lá bài. Blackjack lớn hơn 21 điểm.`,
    "如果您的头两张牌是一张A和一张点数为10的牌，则您获得了Blackjack，此时如果庄家不是Blackjack,您将赢得自己赌注的1.5倍。（如您的赌注是10，则您会收到25）。": `Nếu 2 lá bài đầu của bạn là 1 lá A và 1 lá 10 điểm, bạn giành Blackjack, nếu Nhà Cái không phải Blackjack, bạn thắng 1.5 lần tiền cược (Nếu cược 10, sẽ nhận được 25).`,
    "出现一张牌和小丑牌的面值相同时，游戏结束。": undefined,
    "玩法判定": undefined,
    "如果您的牌的总点数比庄家的更接近21点，您将赢得您的赌注数额（如果您的赌注是10，您则收到20）。": `Nếu tổng điểm bài của bạn gần 21 điểm hơn Nhà Cái, bạn thắng tiền cược (Nếu cược 10, nhận được 20).`,
    "如果您的牌的总点数超过21点，您“爆牌”并失去赌注。": `Nếu tổng điểm bài của bạn >21 điểm, bạn "Bù bài" và mất tiền cược.`,
    "如果您和庄家的总点数相同（17点及以上），则双方都不算赢，您的赌注也会退回，算作平手。": `Nếu tổng điểm bài của bạn và Nhà Cái bằng nhau (≥17 điểm), không bên nào thắng, tiền cược được hoàn trả, coi như Hòa.`,
    "庄家拿牌到16点，在点数达到或超过17后才能停牌（包括软17）。": `Bài Nhà Cái tới 16 điểm, điểm ≥17 mới dừng bài (Bao gồm Mềm 17).`,
    "游戏流程": `Quy trình chơi`,
    "投注主赌注后，可以投注边注玩法（完美对子、21+3）。": `Sau khi cược và xác nhận cược chính, có thể cược cửa cược bên (Đôi hoàn mỹ, 21+3).`,
    "投注轮结束后，使用要牌、停牌、加倍、分牌和保险（如果庄家的第一张牌是A）按钮来参与游戏。": `Sau khi kết thúc cược, dùng nút Rút bài, Dừng, Gấp đôi, Tách bài và Bảo hiểm (Nếu lá bài đầu của Nhà Cái là A) để chơi.`,
    "如果您没有选择操作，您将会自动停牌。如果您选择加倍，则会从余额中拿出与主赌注相等的金额并将您的主赌注翻倍。": `Nếu không chọn sẽ tự động dừng. Nếu chọn Gấp đôi, số tiền tương đương với tiền cược chính sẽ được lấy từ số dư và gấp đôi tiền cược của bạn.`,
    "要牌": `Rút bài`,
    "选择“要牌”继续游戏并再发一张牌。": `Chọn Rút bài để tiếp tục trò chơi và rút thêm bài.`,
    "停牌": `Dừng`,
    "选择“停牌”则该手牌的当前回合结束。": `Chọn Dừng để kết thúc ván hiện tại.`,
    "分牌": `Tách bài`,
    "如果您的前两张牌点数相同（注：10、J、Q、K均为10点），您可将其分成两手独立的牌，并投放与原赌注相同的第二份赌注。然后您可为每手牌分别要牌。分牌后不能再次分牌。": `Nếu điểm của 2 lá bài đầu tiên của bạn giống nhau (lưu ý: 10, J, Q, K đều tính là 10), bạn có thể tách thành 2 phần bài riêng biệt và đặt cược lần hai bằng với cược ban đầu. Sau đó, bạn có thể yêu cầu thêm bài riêng cho mỗi phần. Sau khi chia bài không thể phân lại bài nữa.`,
    "对于分出的每手牌，您都可以要任意数目的牌；但如果将两张A分牌，您只能为每张A再拿一张牌。如果您在分牌后得到一张A和一张点数为十的牌，那么这样的牌只能算作21点，而不算作Blackjack。这种情况下的赔付是1：1,而不是1：1.5。": `Có thể rút số bài tùy ý cho bài đã tách, nhưng nếu tách 2 lá A, mỗi lá A chỉ được rút thêm 1 lá bài. Nếu sau khi tách bài, giành được 1 lá A và 1 lá 10 điểm, sẽ tính 21 điểm, không tính Blackjack, tỷ lệ cược là 1：1, không phải 1：1.5 .`,
    "注：分牌后不可以投注完美对子玩法。": `Chú ý: Tách bài xong không được cược Đôi hoàn mỹ.`,
    "加倍": `Gấp đôi`,
    "在获发前两张牌后，如果您认为靠第三张牌可以赢过庄家的牌，则您可以将赌注加倍。赌注将变成原赌注的两倍（差额从您的余额中扣除），并且您将再获发一张牌。": `Sau khi nhận 2 lá bài, cược gấp đôi nếu bạn thấy có thể thắng bài Nhà Cái bằng lá bài thứ 3. Tiền cược sẽ nhân đôi so với cược ban đầu (Tiền thiếu trừ vào số dư), bạn được phát thêm 1 lá bài.`,
    "您可以在分牌后加倍。": `Có thể gấp đôi sau khi tách bài.`,
    "如果玩家加倍，庄家的第一张牌点数是A,且庄家获得Blackjack，则您将输掉两份赌注（初始赌注和加倍赌注）。": `Nếu người chơi gấp đôi, Nhà Cái lá bài đầu là A và giành Blackjack, bạn sẽ thua 2 phần tiền cược (Cược ban đầu và cược gấp đôi).`,
    "如果庄家第一张牌点数是10，且庄家获得Blackjack，则加倍赌注返还给您。": `Nếu Nhà Cái lá bài đầu là 10 điểm và giành Blackjack, sẽ trả lại bạn tiền cược gấp đôi.`,
    "保险": `Bảo hiểm`,
    "如果庄家的初始牌为A，您将可以投注保险，以防庄家获得Blackjack。保险投注金额为底注的一半。": `Nếu lá bài đầu của Nhà Cái là A, bạn có thể mua bảo hiểm phòng Nhà Cái giành Blackjack. Sau khi mua bảo hiểm, 1 nửa tiền cược ban đầu được đặt riêng trên bàn.`,
    "如果庄家的确有Blackjack，您将按1：2获得保险赔付。": `Nếu Nhà Cái có Blackjack, bạn sẽ được nhận bảo hiểm theo tỷ lệ 1：2 .`,
    "如果庄家没拿到Blackjack，无论您最终输或者赢，您将输掉自己的保险投注。": `Nếu Nhà Cái không có Blackjack, dù bạn thua hay thắng cũng thua cược bảo hiểm.`,
    "10牌自动胜": `10 lá bài tự động thắng`,
    "理论上，您有可能要10张牌但仍未爆牌(一副手牌）。这种情况下，您的手牌将自动获胜，但庄家拿到Blackjack时除外。": `Nếu bạn rút 10 lá bài mà chưa bù bài, bạn sẽ tự động thắng, trừ khi nhà Cái giành Blackjack.`,
    "多座位功能": `Nhiều chỗ ngồi`,
    "我们提供单座模式桌台和双座模式桌台。": `Chúng tôi có hình thức Bàn đơn và Bàn đôi.`,
    "在单座模式桌台内，每个玩家最多可以使用一个座位。": `Ở Bàn đơn, mỗi người chơi nhiều nhất có thể sử dụng một vị trí.`,
    "在双座模式内，玩家可以占据最多2个座位，并在每个座位上分别投注。": `Ở Bàn đôi, người chơi có thể chiếm tối đa 2 vị trí, đồng thời có thể đặt cược riêng biệt mỗi vị trí.`,
    "如果玩家在赌桌上没有已确认的赌注，玩家可以在两局之间或单独游戏局中更换座位。": `Nếu trên bàn không có cược đã xác nhận, người chơi có thể đổi chỗ ngồi giữa 2 ván hoặc 1 ván chơi.`,
    "如果玩家在赌桌上有已确认的赌注，玩家仅可以占据和离开没有投注的座位。": `Nếu người chơi đã xác nhận đặt cược, người chơi chỉ có thể chiếm hoặc rời khỏi các vị trí chưa đặt cược.`,
    "旁注功能（座位后投注）": `Cược phụ (Cược sau chỗ ngồi)`,
    "本游戏提供旁注功能。这样您可以参与游戏，只需跟随主玩家的操作，对他们的手牌下注。": `Trò chơi có thể Cược phụ. Bạn có thể tham gia chơi bằng cách thao tác theo người chơi chính, cược bài của họ.`,
    "只有被主玩家占据的位置上才有旁注选项。您可以同时成为多个位置上的旁注玩家。作为主玩家参与游戏时，您也可以在除自己位置外的所有其他位置上作为旁注玩家投注。": `Vị trí có người chơi chính mới có Cược phụ. Bạn có thể làm người chơi cược phụ cho nhiều vị trí. Nếu làm người chơi chính, bạn cũng có thể cược phụ tại vị trí khác.`,
    "作为旁注玩家，您会自动跟随主玩家的操作，除非他们选择分牌或加倍，而您没有足够的资金这样做。这种情况下，如果主玩家加倍，您会拿牌并停牌；如果主玩家分牌，则按右主手牌操作。": `Người chơi cược phụ sẽ tự động thao tác theo người chơi chính, trừ khi họ chọn tách bài hoặc gấp đôi, còn bạn không đủ tiền. Trường hợp này nếu người chơi chính gấp đôi, bạn sẽ lấy bài và dừng; nếu người chơi chính tách bài, sẽ thao tác theo tụ bài chính bên phải.`,
    "如果您对一个玩家的手牌进行旁注，但该玩家在投注轮决定不下注，您的赌注将返还到您的账户。": `Nếu bạn cược phụ bài của 1 người chơi, nhưng người chơi này không cược, cược của bạn sẽ hoàn trả về tài khoản.`,
    "热门玩家图标帮助您了解当前哪位玩家连胜最高。": `Biểu tượng người chơi hot giúp bạn biết người chơi nào thắng liên tục nhiều nhất.`,
    "数字表示该玩家连胜的局数。如果该玩家输了一局，他将失去热门玩家图标。": `Con số biểu thị số ván thắng liên tục. Nếu người chơi thua 1 ván, sẽ mất biểu tượng người chơi hot.`,
    "发牌方式": `Phương thức phát bài`,
    "我们采用欧式发牌方式。": `Dùng phương thức phát bài kiểu châu Âu.`,
    "庄家在所有玩家都做出决定后获得第二张牌。": `Nhà Cái nhận lá bài thứ 2 sau khi tất cả người chơi đã ra quyết định.`,
    "发牌方式会影响保险和操作回合的顺序：": `Phương thức phát bài ảnh hưởng tới thứ tự bảo hiểm và vòng thao tác:`,
    "对于欧式发牌，当您获得第二张牌且庄家的第一张牌是A时，将提供保险选项。不过您会在保险回合后直接进入操作回合，所有玩家都操作完毕后会将第二张牌发给庄家。": `Nếu phát bài kiểu châu Âu, khi bạn giành lá bài thứ 2 và lá bài đầu của Nhà Cái là A, sẽ có lựa chọn Bảo hiểm. Bạn sẽ vào vòng thao tác sau vòng bảo hiểm, tất cả người chơi thao tác xong sẽ phát lá bài thứ 2 cho Nhà Cái.`,
    "关于21点游戏桌台，我们存在以下2种:": `Về bàn chơi Blackjack, chúng tôi có 2 loại sau:`,
    "1.使用8副牌透明牌靴的桌台，其标志为": `1.Những bàn sử dụng hộp bài trong suốt gồm 8 bộ bài được đánh dấu là `,
    "2.使用6副牌洗牌机的桌台，其标志为": `2.Những bàn sử dụng bộ xáo bài 6 bộ bài được đánh dấu là`,
    "边注": `Cược Bên`,
    "这些是主游戏之外的玩法，必须投注主赌注后才可投注这些玩法。": `Đây là cửa cược ngoài trò chơi chính, cược cược chính rồi mới được cược.`,
    "21+3": `21+3`,
    "玩家可以对发给玩家的前两张牌连同发给庄家的第一张牌下注。": `Người chơi có thể cược 2 lá bài đầu của người chơi và lá bài đầu của Nhà Cái.`,
    "同花三条：所有三张牌的点数和花色都相同": `Bộ 3 Đồng Chất: 3 lá bài cùng điểm số và chất`,
    "同花顺：所有三张牌花色相同、点数依次排列": `Thùng Phá Sảnh: 3 lá bài điểm số liên tiếp và đồng chất`,
    "三条：所有三张牌大小相同": `Bộ 3 Lá: 3 lá bài cùng điểm số`,
    "顺子：所有三张牌花色不同但点数依次排列": `Sảnh: 3 lá bài điểm số liên tiếp và khác chất`,
    "同花：所有三张牌花色相同": `Thùng: 3 lá bài đồng chất`,
    "同花三条": `Bộ ba đồng chất`,
    "(完全相同的三张牌)赔率 1：100": `(3 lá bài giống nhau hoàn toàn) tỷ lệ cược 1：100`,
    "同花顺": `Thùng phá sảnh`,
    "（同花顺子,三张同花顺序）赔率 1：40": `(Thùng Sảnh, 3 lá bài điểm số liên tiếp và đồng chất) tỷ lệ cược 1：40`,
    "三条": `Bộ 3 Lá`,
    "（三张相同牌值的非同花牌）赔率 1：30": `(3 lá bài khác chất cùng điểm số) tỷ lệ cược 1：30`,
    "顺子": `Sảnh`,
    "（非同顺子,三张不同花顺序,包含Q、K、A牌型）赔率 1：10": `(Sảnh khác chất, 3 lá bài điểm số liên tiếp và khác chất, bao gồm bài Q, A, K) tỷ lệ cược 1：10`,
    "同花": `Thùng`,
    "（三张同一花色的牌）赔率 1：5": `(3 lá bài đồng chất) tỷ lệ cược 1：5.`,
    "以玩家首2张牌算": `Tính 2 lá bài đầu của người chơi`,
    "完美对子": `Đôi Hoàn Mỹ`,
    "（闲家一对同色同花同点值）赔率 1：25": `(Người Chơi có 1 đôi bài cùng màu cùng chất cùng điểm số）, tỷ lệ cược 1：25`,
    "同色对子": `Đôi Cùng Màu`,
    "（闲家一对同色同点值不同花）赔率 1：12": `(Người Chơi có 1 đôi cùng màu cùng điểm số khác chất), tỷ lệ cược 1：12`,
    "混合对子": `Đôi Hỗn Hợp`,
    "（闲家一对不同色不同花同点值）赔率 1：6": `(Người Chơi có 1 đôi bài khác màu khác chất cùng điểm số) Tỷ lệ cược 1:6`,
    "赔率表": `Bảng tỷ lệ`,
    "玩法赔率": `Tỷ lệ cửa cược`,
    "主游戏": `Trò Chơi Chính`,
    "赔率": `Tỷ lệ`,
    "投注项目": `Mục đặt cược`,
    "主赌注": `Cược chính`,
    "Blackjack": `Blackjack`,
    "21+3边注玩法": `Cược Bên 21+3`,
    "完美对子边注玩法": `Đôi Hoàn Mỹ`,
    "注意事项": `Những điều cần chú ý`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "所有入座玩家必须先下注某个位置的底注才能下注该位置的完美对子和21+3玩法。": `
Tất cả người chơi trong ván này buộc phải đặt cược ở một vị trí Cược gốc trước, sau đó mới được cược Đôi hoàn mỹ và 21+3`,
    "如果赌桌上的每位玩家均爆牌（点数高于21点），那么这局游戏结束；庄家不需要拿牌。如果赌桌上的每位玩家均爆牌（点数高于21点），但庄家底牌为A,庄家需要拿1牌，以判断庄家是否为BJ。": `Nếu người chơi trên bàn đều bù bài (>21 điểm), ván này kết thúc; Nhà Cái không cần lấy bài. Nếu người chơi trên bàn đều bù bài (>21 điểm), bài gốc của Nhà Cái là A, Nhà Cái rút 1 lá xem có BJ không.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官发牌时，偶有系统无法判读状况时，此时荷官将重新扫描，直到系统能完整判读（如遇故障将取消所有相关注单/派彩）。": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).`,
    "如遇结算错误，按照当期视频结果重新结算。": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `Nếu trong quá trình chơi, vì sự cố kết nối mạng hoặc các vấn đề khác của Nhà cái khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy ván và hoàn lại tiền cược.`,
    "若玩家已经是Blackjack后出现系统故障，该玩家仍然按照1:1.5进行派彩。": `Nếu sự cố xuất hiện sau khi người chơi là Blackjack, tỉ lệ trả thưởng cho người chơi vẫn sẽ là 1:1.5`,
    "如果您从游戏中断开，任何已确定赌注将保持有效，并在您不在场的时候结算。重新连接后，您可以在“投注记录”中查看结果。": `Nếu bạn bị ngắt kết nối trong quá trình chơi, cược đã xác nhận vẫn có hiệu lực và kết toán. Sau khi kết nối lại, bạn có thể vào "Đặt cược" xem kết quả.`
  },
  "Roulette": {
    "轮盘": `Roulette`,
    "游戏规则": `Quy tắc chơi`,
    "荷官转动轮盘，并抛出象牙制滚球到轮盘外侧；": `Dealer quay roulette và ném một quả bóng bằng ngà voi vào bên ngoài roulette;`,
    "当滚球在轮盘内转动多周后减速并落入下方格子；": `Sau khi quay nhiều vòng trên đường biên của roulette, bóng sẽ giảm tốc độ và rơi xuống ô vuông bên dưới;`,
    "该格子所对应的数字，作为该局结果；": `Con số tương ứng với ô vuông này là kết quả của ván;`,
    "若滚球在轮盘转动小于或等于5圈，需要重新打球。": `Nếu bóng quay ít hơn hoặc bằng 5 vòng trên Roulette, cần quay lại bóng.`,
    "玩法判定": `Cửa cược`,
    "美式注盘": `Cược kiểu Mỹ`,
    "美式下注盘也叫经典盘，包含了直注以及各种组合下注。玩家可在游戏中切换美式和法式下注盘。": `Cược kiểu Mỹ còn được gọi là cược truyền thống, bao gồm cược đơn và các loại cược tổ hợp.Người chơi có thể chuyển đổi giữa bố cục cược kiểu Mỹ và Pháp trong suốt trò chơi.`,
    "美式-直注": `Kiểu Mỹ - Cược đơn`,
    "即直接点击数字方块，对0-36的数字直接下注。": `Trực tiếp chọn các ô vuông số, cược các số 0-36.`,
    "例如，下注11点，只有当结果是11时才会胜出。": `Ví dụ cược 11 điểm, chỉ khi kết quả là 11 mới thắng.`,
    "美式-分注": `Kiểu Mỹ - Cược Tách`,
    "即在线上下注，当结果为线两边的数字时胜出。": `Cược trên vách ngăn giữa 2 số, nếu kết quả là con số ở 2 bên vách ngăn thì thắng.`,
    "例如，下注3和6之间的线，或者2和3之间的线。": `Ví dụ, cược trên vách ngăn giữa 3 và 6, hoặc giữa 2 và 3.`,
    "美式-三数": `Kiểu Mỹ - Ba số`,
    "三数为同时投注包含0在内的三个数字。": `Ba số là cùng lúc cược ba số chứa số 0.`,
    "例如，0，1，2为一组。0，2，3为一组。": `Ví dụ 0, 1, 2 là một nhóm. 0, 2, 3 là một nhóm.`,
    "美式-四号": `Kiểu Mỹ - Cược bốn số`,
    "同时对0，1，2，3四个数字进行下注。": `Cùng lúc cược bốn số 0, 1, 2, 3.`,
    "美式-角注": `Kiểu Mỹ - Cược Góc`,
    "对4个数字方框交叉处点击。": `Bấm vào giao điểm của 4 ô vuông số.`,
    "例如：1，2，4，5为一注。2，3，5，6为一注。": `Ví dụ: 1, 2, 4, 5 là một cược. 2, 3, 5, 6 là một cược.`,
    "美式-街注": `Kiểu Mỹ - Cược Hàng`,
    "对3个相连的数字投注。": `Đặt cược ba số liên tiếp.`,
    "例如：1，2，3为一组。7，8，9为一组。": `Ví dụ: 1, 2, 3 là một nhóm. 7, 8, 9 là một nhóm.`,
    "美式-线注": `Kiểu Mỹ - Cược Dòng`,
    "类似街注，区别在于对2个街注相交的位置下注，同时下注6个数字。": `Tương tự như cược hàng, chỉ khác là cược tại vị trí giao nhau của 2 cược hàng, cùng lúc cược 6 số.`,
    "例如：1，2，3，4，5，6一注。4，5，6，7，8，9一注。": `Ví dụ: 1, 2, 3, 4, 5, 6 là một cược. 4, 5, 6, 7, 8, 9 là một cược.`,
    "美式-列注": `Kiểu Mỹ - Cược Cột`,
    "每列12个号码，对“第X列”处下注。": `Mỗi cột 12 số, cược tại "Cột x".`,
    "例如：第一列包括1，4，7，10，13，16，19，22，25，28，31，34，共12个数字。": `Ví dụ: Cột 1 bao gồm 1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, tất cả 12 số.`,
    "美式-打注": `Kiểu Mỹ - Cược Tá`,
    "将筹码下到“第X打”的位置。": `Đặt chip vào vị trí "Tá X".`,
    "例如：第一打包括1-12。第二打为13-24。第三打为25-36。": `Ví dụ：Tá 1 bao gồm 1-12. Tá 2: 13-24. Tá 3: 25-36.`,
    "美式-红黑": `Kiểu Mỹ - Đỏ/Đen`,
    "对桌台上标注红，黑的位置下注。若开0则通杀，红/黑均输。": `Cược vị trí được đánh dấu là đỏ, đen trên bàn. Nếu kết quả là 0 thì đỏ/đen đều thua.`,
    "例如：所有为红色格子的数字或所有黑色格子的数字。": `Ví dụ: Tất cả số thuộc ô màu đỏ hoặc tất cả số thuộc ô màu đen.`,
    "美式-单双": `Kiểu Mỹ - Lẻ/Chẵn`,
    "对桌台上标注单，双的位置下注。若开0则通杀，单/双均输。": `Cược vị trí được đánh dấu là lẻ, chẵn trên bàn. Nếu kết quả là 0 thì lẻ/chẵn đều thua.`,
    "例如：单数包括1，3，5……35的单数。双则表示2，4，6……36的双数。": `Ví dụ: Số lẻ bao gồm các số lẻ 1, 3, 5… 35. Số chẵn bao gồm các số chẵn 2, 4, 6… 36.`,
    "美式-大小": `Kiểu Mỹ - Tài/Xỉu`,
    "对桌台上标注大，小的位置下注。若开0则通杀，大/小均输。": `Cược vị trí được đánh dấu là Tài, Xỉu ở trên bàn. Nếu kết quả là 0 thì Tài/Xỉu đều thua.`,
    "例如：小（1-18）。大（19-36）。": `Ví dụ: Xỉu (1-18). Tài (19-36).`,
    "法式注盘": `Cược kiểu Pháp`,
    "也叫欧式注盘，可视为对美式注盘的衍生。将美式注盘的一部分打包组合后一起下注的玩法。": `Còn được gọi là cược kiểu châu Âu, có thể coi là biến thể của cược kiểu Mỹ, là cách chơi đóng gói tổ hợp 1 phần cược kiểu Mỹ rồi cùng đặt cược.`,
    "法式-相邻投注：": `Kiểu Pháp - Cược Neighbors`,
    "点击法式轮盘的格子，同时对点击格子左邻和右邻两个号码（一共5号码），按顺序各下一注。": `Bấm chọn ô của Roulette kiểu Pháp, cùng lúc chọn một ô và hai số bên trái phải của số đó (Tất cả 5 số), theo thứ tự cược 5 cược.`,
    "例如：选中5，则投注号码为23，10，5，24，16。": `Ví dụ: Chọn 5 thì số đặt cược là 23, 10, 5, 24, 16.`,
    "法式-轮上零旁：": `Kiểu Pháp - Zero game:`,
    "点击轮上零旁区域。": `Bấm chọn khu vực Zero game trên Roulette.`,
    "对0和3；12和15；32和35；26；各下一注。": `Cược 0 và 3; 12 và 15; 32 và 35; 26.`,
    "法式-零旁注上角：": `Kiểu Pháp - Neighbors Of Zero:`,
    "点击零旁注上角区域，对以下区域和组合进行下注。": `Bấm chọn khu vực Neighbors Of Zero trên Roulette, cược khu vực hoặc tổ hợp dưới đây.`,
    "4和7；12和15；18和21；19和22；32和35；5个组合各一注；": `4 và 7; 12 và 15; 18 và 21; 19 và 22; 32 và 35; 5 tổ hợp mỗi tổ hợp một cược;`,
    "0，2，3的组合，下两注；": `Tổ hợp của 0, 2, 3, cược hai cược;`,
    "25，26，28，29的组合，下两注。": `Tổ hợp 25, 26, 28, 29, cược hai cược.`,
    "法式-轮上孤注：": `Orphelins`,
    "点击轮盘孤注，对以下区域组合进行下注。": `Bấm chọn khu vực Orphelins, cược khu vực hoặc tổ hợp dưới đây.`,
    "即轮盘枱上的1号，6号，9号；14号，17号；17号，20号；31号，和34号几种组合各投1注。": `Nghĩa là cược các tổ hợp số 1, 6, 9; số 14, 17; số 17, 20; số 31, và số 34 trên Roulette, mỗi tổ hợp 1 cược.`,
    "法式-轮盘下角注：": `Pháp - Cược Góc Dưới:`,
    "法式-轮盘下角注。": `Pháp - Cược Góc Dưới.`,
    "5和8；10和11；13和16；23和24；27和30；33和36这六个玩法下1注。": `Cược 6 cửa cược 5 và 8; 10 và 11; 13 và 16; 23 và 24; 27 và 30; 33 và 36 mỗi loại một cược.
`,
    "小球落入的格子对应信息包含数字，红黑。数字分大小，单双；": `Thông tin tương ứng của ô mà bóng rơi vào bao gồm con số, đỏ đen. Con số phân thành tài xỉu, lẻ chẵn;`,
    "同时根据数字在下注盘的位置，分为“打”和“列”；": `Đồng thời căn cứ vào vị trí của con số trên bàn cược, chia thành "Tá" và "Cột";`,
    "也有根据数字所在下注盘位置，分为不同组的玩法。": `Cũng căn cứ vào vị trí của con số trên bàn cược, chia thành cửa cược thuộc nhóm khác nhau.`,
    "赔率表": `Bảng tỷ lệ`,
    "由于法式下注盘只是对美式下注盘的玩法打包，其赔率对应到美式下注盘，因此赔率以美式为主。": `Bàn cược kiểu Pháp chỉ gói gọn cách chơi của bàn cược kiểu Mỹ nên tỷ lệ của cược kiểu Pháp tương ứng với bàn cược kiểu Mỹ, do vậy bảng tỷ lệ chủ yếu là kiểu Mỹ.`,
    "投注项目": `Mục đặt cược`,
    "赔率": `Tỷ lệ`,
    "直注": `Cược đơn`,
    "分注": `Cược Tách`,
    "街注": `Cược Hàng`,
    "三数": `Ba số`,
    "角注": `Cược Góc`,
    "四号": `Cược bốn số`,
    "线注": `Cược Dòng`,
    "列注": `Cược Cột`,
    "打注": `Cược Tá`,
    "红/黑": `Đỏ/Đen`,
    "单/双": `Lẻ/Chẵn`,
    "大/小": `Tài/Xỉu`,
    "游戏特点": `Đặc điểm trò chơi`,
    "欧美常见的竞猜游戏，一度风靡于皇族和贵族之间，现代社会才步入平民娱乐。被誉为最高贵的游戏。以下注玩法多维度著称，单押直注追求高赔率，或多点投入追求中奖率，休闲与刺激兼顾。": `Đây là trò chơi thi đoán phổ biến ở các nước Âu Mỹ, đã từng phổ biến trong giới hoàng gia và quý tộc, tới xã hội hiện đại mới trở thành trò giải trí của người dân thường. Đây từng được biết đến như là một trò chơi cao quý nhất, nổi tiếng với cách chơi đa chiều, cược đơn theo đuổi tỷ lệ cao hoặc nhiều điểm cược theo đuổi tỷ lệ chiến thắng, vừa giải trí vừa kích thích.`,
    "注意事项": `Những điều cần chú ý`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "如遇结算错误，按照当期视频结果重新结算。": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`
  },
  "Fantan": {
    "番摊": `FanTan`,
    "游戏规则": `Quy tắc chơi`,
    "游戏使用钮扣200颗，新局开始后，荷官用铁碗从钮扣堆中取出不知数的钮扣，开始下注。": `Trò chơi  sử dụng 200 hạt. Sau khi bắt đầu vòng chơi mới, người chia bài sử dụng một chiếc bát úp ngược để chọn ngẫu nhiên một số hạt từ một chồng hạt, sau đó bắt đầu đặt cược.`,
    "下注结束后，荷官即翻开铁碗收皮，用一个棒子将钮扣每4个为一列排好，直到剩下4或少于4个为止。": `Sau khi thời gian cược kết thúc, người chia bài sẽ mở bát, dùng một chiếc que đặc biệt để sắp xếp các hạt đã chọn thành từng nhóm gồm 4 hạt, cho đến khi còn lại 4 hạt hoặc ít hơn.`,
    "最后剩下的钮扣数即为本局开奖结果，画面会显示玩家的输赢情况。": `Số hạt còn lại cuối cùng sẽ là kết quả của ván chơi, và màn hình sẽ hiển thị tình trạng thắng thua của người chơi.`,
    "玩法判定": `Cửa cược`,
    "单：1、3为单；": `Lẻ: 1, 3 là số lẻ;`,
    "双：2、4为双。": `Chẵn: 2, 4 là số chẵn.`,
    "番：投注单一号码，开出为赢，其余为输。如：1番，开1为赢，2、3、4为输；": `Fan: Cược 1 số, mở số đó là thắng, còn lại là thua. VD: 1 Fan, mở 1 là thắng; 2, 3, 4 là thua;`,
    "念：投注两个号码，一个为赢，一个为和，另外两个为输 如：1念2，开1为赢，开2为和，3、4为输；": `Nim: Cược 2 số, 1 số là thắng, 1 số là hòa, 2 số khác là thua. VD: 1 Nim 2, mở 1 là thắng, mở 2 là Hòa, 3, 4 là thua;`,
    "角：投注两个号码，开出为赢，其余为输。如：1.2角，开1、2为赢，3、4为输；": `Kwok: Cược 2 số, mở số đó là thắng, còn lại là thua. VD: 1.2 Kwok, mở 1, 2 là thắng, 3, 4 là thua;`,
    "通：投注两个号码，开出为赢，其余两个号码一输一和。如：12三通，则开1、2为赢，3为输，4为和；": `Nga: Cược 2 số, mở số đó là thắng, 2 số còn lại 1 thua 1 hòa. VD: 12 3Nga, mở 1, 2 là thắng, 3 là thua, 4 là Hòa;`,
    "三门：投注三个号码，如果开出的结果是三个号码之一，则中奖；其他则输。如：三门123，开1、2、3为赢，4为输；": `SSH: Cược 3 số, nếu kết quả mở 1 trong 3 số thì thắng, còn lại thua. VD: SSH 123, mở 1, 2, 3 là thắng, 4 là thua;`,
    "赔率表": `Bảng tỷ lệ`,
    "玩法赔率": `Tỷ lệ cửa cược`,
    "投注项目": `Mục đặt cược`,
    "赔率": `Tỷ lệ`,
    "单": `Lẻ`,
    "双": `Chẵn`,
    "番": `Fan`,
    "念": `Nim`,
    "角": `Kwok`,
    "通": `Nga`,
    "三门": `SsH`,
    "游戏特点": `Đặc điểm trò chơi
`,
    "番摊是中国古老的做庄游戏，流行于两广一带，当年广摊只有「番」一种玩法，故名番摊，时至今日，番摊玩法已改变不少，除了番之外还多了念、角、通、门、单双这几种玩法。": `Fantan là trò chơi cổ của Trung Quốc, phổ biến ở Quảng Đông và Quảng Tây. Ban đầu Fantan chỉ có cửa cược [Fan], ngày nay ngoài Fan ra còn có Nim, Kwok, Nga, SSH, Lẻ/Chẵn.`,
    "注意事项": `Những điều cần chú ý`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "如遇结算错误，按照当期视频结果重新结算。": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`
  },
  "FriedGoldenFlower": {
    "炸金花": `Nổ kim hoa`,
    "游戏规则": `Quy tắc chơi`,
    "使用一副牌游戏（去大小王）进行游戏；": `Sử dụng một bộ bài (Bỏ các lá Joker) để chơi;`,
    "先比较牌型大小，若牌型一致再按从大到小的顺序依次比较牌面大小，决定胜负。": `So kiểu bài trước, nếu kiểu bài như nhau thì so sánh điểm số theo thứ tự từ lớn đến nhỏ để quyết định thắng thua.`,
    "玩法判定": `Cửa cược`,
    "牌型": `Kiểu bài`,
    "豹子杀手：": `Sát thủ bão:`,
    "三张非同花的2，3，5；": `Ba lá 2, 3, 5 không cùng chất;`,
    "豹子：": `Bão:`,
    "三张点数相同的牌；": `Ba lá bài cùng điểm số;`,
    "同花顺：": `Thùng phá sảnh：`,
    "花色相同的顺子牌；": `Bài theo thứ tự cùng chất;`,
    "同花：": `Thùng:`,
    "花色相同的任意三张牌；": `Ba lá bài tùy ý có cùng chất;`,
    "顺子：": `Sảnh:`,
    "三张连续的牌；": `Ba lá bài theo thứ tự;`,
    "对子：": `Đôi:`,
    "两张点数相同的牌；": `Hai lá bài cùng điểm số;`,
    "散牌：": `Bài lẻ:`,
    "无法组成任何类型的牌；": `Bài không thể tạo thành kiểu bài gì.`,
    "牌型大小": `So Kiểu bài`,
    ">豹子＞同花顺＞同花＞顺子＞对子＞散牌；": `Bão > Thùng Phá sảnh > Thùng > Sảnh > Đôi > Bài Lẻ;`,
    "豹子杀手＞豹子；": `Sát thủ bão > Bão;`,
    "当豹子牌和豹子杀手牌同时存在时，豹子杀手牌比豹子牌大；": `Nếu bài bão và bài sát thủ bão cùng xuất hiện thì bài sát thủ bão lớn hơn;`,
    "散牌＞豹子杀手；": `Bài Lẻ > Sát thủ bão;`,
    "如不存在豹子牌，豹子杀手是最小的散牌牌型。": `Nếu không có bài bão thì bài sát thủ bão là kiểu bài lẻ nhỏ nhất.`,
    "对子大小": `Đôi lớn nhỏ`,
    "如果两家都是对子牌，即比对对子点数大小，对A＞对K＞对Q＞对J＞对10＞对9＞对8＞对7＞对6＞对5＞对4＞对3＞对2；": `Nếu hai nhà đều là bài đôi thì sẽ so sánh điểm số của các đôi, Đôi A＞Đôi K＞Đôi Q＞Đôi J＞Đôi 10＞Đôi 9＞Đôi 8＞Đôi 7＞Đôi 6＞Đôi 5＞Đôi 4＞Đôi 3＞Đôi 2;`,
    "两对对子的点数相同，再比单牌点数大小。": `Nếu điểm của hai đôi như nhau thì so điểm số của lá bài riêng lẻ.
`,
    "豹子、同花、散牌牌型": `Kiểu bài bão, thùng, bài lẻ`,
    "比最大那张牌的点数，如最大的那张点数相同，则比第二大牌的点数，以此类推；_A>K>Q>J>10>9>8>7>6>5>4>3>2，花色不分大小。": `So điểm của lá bài lớn nhất, nếu lá lớn nhất bằng điểm nhau thì so điểm của lá lớn thứ hai, tương tự như vậy; A>K>Q>J>10>9>8>7>6>5>4>3>2, chất bài không phân lớn nhỏ.`,
    "同花顺，顺子": `Thùng phá sảnh, Sảnh`,
    "按照顺序比点数：AKQ>KQJ>......>432>32A": `So điểm theo thứ tự: AKQ>KQJ>......>432>32A`,
    "注意：只有在同花顺和顺子牌型中，A可作为最大或最小的牌，其余牌型中A为最大的单牌": `Lưu ý: Chỉ trong Thùng Phá Sảnh và Sảnh, A mới được xem là quân bài lớn nhất hoặc nhỏ nhất; trong các loại bài khác, A là quân bài đơn lớn nhất
`,
    "赔率表": `Bảng tỷ lệ`,
    "玩家赢赔率": `Tỷ Lệ Thắng của Người Chơi`,
    "玩家输赔率": `Tỷ Lệ Thua của Người Chơi`,
    "胜方牌型": `Kiểu bài bên thắng`,
    "平倍": `Thường`,
    "翻倍": `Gấp bội`,
    "超倍": `Siêu bội`,
    "单牌": `Bài lẻ`,
    "单牌以下": `Bài lẻ {value} trở xuống`,
    "对子": `Đôi`,
    "顺子": `Sảnh`,
    "同花": `Thùng`,
    "同花顺": `Thùng phá sảnh`,
    "三条": `Bão`,
    "豹子杀手": `Sát thủ bão`,
    "游戏特点": `Đặc điểm trò chơi`,
    "炸金花，也被称为赢三张。是一种广泛流传的民间游戏。": `Nổ Kim Hoa còn được gọi là Thắng Ba Lá, là trò chơi dân gian được lưu truyền rộng rãi.`,
    "注意事项": `Những điều cần chú ý`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Một số cửa cược có thể giới hạn số ván cược của hộp bài, chi tiết xem nhắc nhở liên quan.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).
`,
    "如遇结算错误，按照当期视频结果重新结算。": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `Nếu trong quá trình chơi, vì sự cố mạng của nhà cái… khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy bỏ vé cược của ván và hoàn lại tiền cược.`
  },
  "fishShrimpCrab": {
    "越南鱼虾蟹": `Cá Tôm Cua Việt`,
    "游戏规则": `Quy tắc chơi`,
    "玩家押注的内容与三颗骰子停留开出的图案相同，便赢得彩金。": `Người chơi thắng tiền thưởng khi nội dung đặt cược trùng với hình ảnh xuất hiện trên ba viên xúc xắc`,
    "在骰盅里装有3个骰子，每颗骰子分别为：": `Trong cốc xúc xắc có 3 viên xúc xắc, mỗi viên có các hình`,
    "1.鹿": `1.Nai`,
    "2.葫芦": `2.Bầu`,
    "3.虾": `3.Tôm`,
    "4.鱼": `4.Cá`,
    "5.鸡": `5.Gà`,
    "6.蟹": `6.Cua`,
    "新一轮开始后，25秒投注倒计时开始": `Sau khi vòng mới bắt đầu, đếm ngược thời gian đặt cược 25 giây sẽ bắt đầu`,
    "您可以选择在任何一个或多个投注区域下注": `Bạn có thể chọn đặt cược tại bất kỳ khu vực nào hoặc nhiều khu vực`,
    "单笔投注金额不能分别高于或低于单笔投注上限和下限": `Số tiền đặt cược cho mỗi lần không được cao hơn hoặc thấp hơn giới hạn tối đa và tối thiểu của mỗi lần đặt cược`,
    "投注倒计时结束后，不得再下注，所有已下注均不可更改": `Sau khi thời gian đếm ngược đặt cược kết thúc, không được phép đặt cược thêm, tất cả các cược đã đặt đều không thể thay đổi`,
    "荷官开始按游戏开始键，中心圈内往下移动，并开始震动，持续震动5秒，停摆后，荷官按照骰子输入结果，震动低于4秒，该局游戏则视为无效局": `Nhà cái sẽ nhấn nút bắt đầu trò chơi, vòng trung tâm sẽ di chuyển xuống dưới và bắt đầu lắc, lắc liên tục trong 5 giây. Sau khi dừng lại, nhà cái sẽ nhập kết quả xúc xắc. Nếu thời gian lắc dưới 4 giây, ván chơi đó sẽ được coi là ván không hợp lệ`,
    "玩法判定": `Cửa cược`,
    "赔率表": `Bảng tỷ lệ`,
    "玩法赔率": `Tỷ lệ cửa cược`,
    "投注项目": `Mục đặt cược`,
    "赔率": `Tỷ lệ`,
    "说明": `Mô tả`,
    "单点": `Đơn`,
    "1:1": `1:1`,
    "1:2": `1:2`,
    "1:3": `1:3`,
    "1:1 投注的指定图案出现1次": `1:1 hình được chọn xuất hiện 1 lần`,
    "1:2 投注的指定图案出现2次": `1:2 hình được chọn xuất hiện 2 lần`,
    "1:3 投注的指定图案出现3次": `1:3 hình được chọn xuất hiện 3 lần`,
    "短牌": `Tổ hợp`,
    "1:5": `1:5`,
    "共15组，2个骰子可能出现的图案组合，蟹+葫芦、鱼+虾、葫芦+鸡...，下注的两个图案皆出现算赢。": `Gồm 15 tổ hợp, 2 xúc xắc cho ra các loại kết hợp: cua + nai, nai + tôm, nai + gà..., hai hình chỉ định đều xuất hiện sẽ thắng`,
    "全围": `Bộ 3 đồng nhất`,
    "1:30": `1:30`,
    "三颗骰子图案相同即全围，包含6种围骰组合，出现任意一种即可胜出。": `Ba viên xúc xắc có hình ảnh giống nhau gọi là bộ 3 đồng nhất, bao gồm 6 loại kết hợp xúc xắc, xuất hiện bất kỳ loại nào cũng thắng`,
    "长牌/对子": `Đôi`,
    "1:10": `1:10`,
    "三颗骰子中，任意两颗骰子图案相同即为长牌/对子。": `Trong ba viên xúc xắc, bất kỳ hai viên nào có hình giống nhau thì được coi là bài đôi`,
    "注意事项": `Những điều cần chú ý`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红": `Giới hạn bàn là giới hạn tối thiểu và tối đa của các cách chơi chính trên bàn này.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）": `Khi Dealer mở bài, nếu hệ thống không thể phân biệt, Dealer sẽ thao tác để hệ thống có thể nhận biết chính xác (Nếu có sự cố phải hủy bỏ tất cả vé cược/trả thưởng liên quan).`,
    "如遇结算错误，按照当期视频结果重新结算": `Nếu kết toán lỗi, sẽ kết toán lại theo kết quả video của kỳ đó.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金": `Nếu trong quá trình chơi, vì sự cố kết nối mạng hoặc các vấn đề khác của Nhà cái khiến không thể hoàn thành ván bài một cách bình thường, hệ thống sẽ hủy ván và hoàn lại tiền cược.`
  }
};

export default vi;