<template>
  <div class="top-icon" @click="onTop">
    <img src="../../images/top.svg" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.onScroll, false);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      let classArr =  document.getElementsByClassName("top-icon");
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scrollTop < 60) {
        classArr[0].classList.add("top-icon-hide");
      } else {
        classArr[0].classList.remove("top-icon-hide");
      }
    },
    onTop() {
      let timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 5);
    },
  },
};
</script>

<style lang="scss" scoped>
.top-icon {
  width: 6.4vw;
  height: 6.4vw;
  position: fixed;
  bottom: 26.667vw;
  right: 1.6vw;
  transform: translate3d(0, -100%, 0);
  opacity: 1;
  transition: all 1.5s;
  img {
    width: 100%;
    height: 100%;
  }
}
.top-icon-hide {
  transform: translateZ(0);
  opacity: 0;
}
</style>
