<template>
  <div class="close" @click="onClose">
    <img src="../../images/close.svg" alt="" />
  </div>
</template>

<script>
export default {
  methods: {
    onClose() {
      console.log("触发---parent.postMessage");
      parent.postMessage("------------hello!-----cocos---------", "*")
    },
  },
};
</script>

<style scoped lang="scss">
.close {
  position: absolute;
  right: 5.333vw;
  top: 8.667vw;
  width: 6.4vw;
  //   opacity: 0;
  img {
    width: 100%;
  }
}
</style>
