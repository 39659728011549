// 发布订阅模式

class Subscribe {
    constructor() {
      // 订阅事件中心
      this.events = {}
    }
    // 订阅
    subscribe(eventName, callback) {
      if (!this.events[eventName]) {
        this.events[eventName] = []
      }
      this.events[eventName].push(callback)
    }
    // 发布事件
    publish(eventName, ...args) {
      this.events[eventName] && this.events[eventName].forEach(cb => cb(...args))
    }
    // 取消订阅
    unsubscribe(eventName, callback) {
      if (this.events[eventName]) {
        this.events[eventName] = this.events[eventName].filter(cb => cb != callback)
      }
      if (this.events[eventName].length === 0) delete this.events[eventName]
    }
  }
  
  export default Subscribe
  