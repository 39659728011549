/**
 * @description 公共工具类
 */
class CommonUtil{
    constructor(){}

    /**
     * @description 发布订阅事件名称 
     */
   static EventName = {
        /**
         * @description 底部导航栏变化事件
         */
        tabListChange : "tabListChange",
        /**
         * @description 图片容器更换
         */
        imgMapChange : "imgMapChange",
    }
}   

export default CommonUtil