<template>
  <div id="app">
    <router-view  v-if="!loading" />

    <van-loading class="app_load" v-else type="spinner" size="40px">
      加载中...
    </van-loading>
  </div>
</template>

<script>
  import CommonUtil from '@/utils/commonUtil'
export default {
  name: "App",
  data() {
    return {
      gameTypeHelp: "",
      loading: true
    };
  },
  created() {
    this.initRouter();
  },
  methods: {
    initRouter() {
      const router = this.$router;
      // console.log(this.$i18n,"=============")
      window.onmessage = (e) => {
        // console.log('接受iframe参数',e);
        if (e.data.type == "fromCocos") {
          localStorage.setItem("lang_help", e.data.data.gameType_lang);
          // console.log( e.data.data.gameType_lang,"这里没有生效吗")
          this.gameType_help = e.data.data.gameType_help;
          const routerUrl = {
            bjl: "/Bacarat", // 百家乐
            longhu: "/DragonTiger", // 龙虎
            niuniu: "/Bulls", // 牛牛
            sangong: "/SanGong", // 三公
            goldenFlower: "/GoldenFlower", // 龙凤炸金花
            texasHoldem: "/TexasHoldem", // 德州扑克
            sicBo: "/SicBo", // 骰宝
            seDie: "/SeDie", // 色碟
            roulette: "/Roulette", // 轮盘
            fantan: "/FanTan", // 番摊
            friedGoldenFlower: "/FriedGoldenFlower", // 炸金花
            AndarBahar:"/AndarBahar",//安达巴哈
            Blackjack:"/Blackjack",//21点
            yxx:"/fishShrimpCrab",//21点
          };
          if (this.gameType_help) {
            router.push(routerUrl[this.gameType_help]);
                       //语言设置
            this.$i18n.locale =  e.data.data.gameType_lang
           
            this.$Subscribe.publish(CommonUtil.EventName.imgMapChange,  e.data.data.gameType_lang)
            // console.log("执行了嘛")
          }          
        }

        setTimeout(() => {
          this.loading = false
        }, 500)
        // this.$nextTick(() => this.loading = false)
      };
    },
  },
};
</script>

<style>
body {
  -webkit-font-smoothing: antialiased;
}

body,
button,
dd,
div,
dl,
figure,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
ol,
p,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

em {
  font-style: italic;
}

#app {
  /* height: 100%; */
  position: relative; /* 确保父容器为定位容器 */

  font-family: Microsoft YaHei, -apple-system, BlinkMacSystemFont, Segoe UI,
    PingFang SC, Hiragino Sans GB, Helvetica Neue, Helvetica, Arial, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  color: #666;
}

.app_load{
  position: absolute; /* 相对于父容器绝对定位 */
  top: 400px; 
  left: 40%;  
}
</style>
