<template>
    <div class="container">
      <div class="scrollArea">
        <!-- <div class="details_header"></div> -->
        <Header @Language="Language" />
        <div class="det_container">
          <div class="p_details_header">
            <div class="name">
              <p class="zh_name">{{ getRouteTitie}}</p>
            </div>
          </div>
          <router-view />
        </div>
      </div>
      <Top />
      <div class="footer">
        <Tab :tabList="tabList" />
      </div>
    </div>
  </template>
  
  <script>
  import Tab from "@/components/Tab/index.vue";
  import Header from "@/components/header/index.vue";
  import Top from "@/components/top/index.vue";
  import CommonUtil from '@/utils/commonUtil'
  import $ from "@/lang/index";
  export default {
    name: "LAYOUT",
    components: {
      Tab,
      Header,
      Top,
    },
    data() {
      return {
        imgMap: {},
        tabList:[]
      };
    },
    computed: {
      getRouteTitie(){   
        const routeMapTitle = {
          'Bacarat':$.t("Bacarat['d1']"),
          'DragonTiger':$.t("DragonTiger['d1']"),
          "Bulls":$.t("Bulls['d1']"),
          "SanGong":$.t("SanGong['d1']"),
          "GoldenFlower":$.t("GoldenFlower['龙凤炸金花']"),
          "TexasHoldem":$.t("TexasHoldem['德州扑克']"),
          "SicBo":$.t("SicBo['骰宝']"),
          "SeDie":$.t("SeDie['色碟']"),
          "AndarBahar":$.t("AndarBahar['安达巴哈']"),
          "Blackjack":$.t("Blackjack['21点']"),
          "Roulette":$.t("Roulette['轮盘']"),
          "Fantan":$.t("Fantan['番摊']"),
          "FriedGoldenFlower":$.t("FriedGoldenFlower['炸金花']"),
          "fishShrimpCrab":$.t("fishShrimpCrab['越南鱼虾蟹']")
        }
        // console.log(this.$route,"===========")
        return routeMapTitle[this.$route.name]
            // 将路径转换为首字母大写格式
          // const normalizedPath = this.$route.path.replace(/\/\w/g, (match) =>
          //  match.toUpperCase()
          // );
          // return routeMapTitle[normalizedPath];

          },
     
          },

    created(){
       // 订阅 设置滚动列表
       this.$Subscribe.subscribe(CommonUtil.EventName.tabListChange, this.setTabList)
    },
    mounted() {
      this.$nextTick(()=>{
        this.setLang()
      })
    },
  
    methods: {
      setLang() {
         let lang = localStorage.getItem("lang_help");
         this.Language(lang)
      },
      Language(lang) {
        if(typeof lang == 'string'){
          this.$Subscribe.publish(CommonUtil.EventName.imgMapChange, lang)
        }else{
          this.$Subscribe.publish(CommonUtil.EventName.imgMapChange, lang.lang)
        }
      },
      
      // 收到订阅传参
      setTabList(data){
        this.tabList = data
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "../css/index.scss";
  </style>
  