const zh = {
  Header:{
    d1:`语言设定`,
    d2:`语言是否切换为`,
    d3:`取消`,
    d4:`确定`,
  },
  Bacarat: {
    d1: "经典百家乐",
    d2: "1. 游戏规则",
    d3: "采用8副牌（去大小王），共计416张；",
    d4: " “闲家” “庄家” 各派两张牌，先 “闲” 后 “庄”（开牌顺序亦同），如首轮未分胜负，再按 “牌例” 博牌，最多各发1张牌定输赢； ",
    d5: "任何一家拿到8点或9点（天生赢家），牌局会使用前4张牌进行结算，不再博牌；",
    d6: "最终以闲家和庄家的和值个位比大小",
    d7: "2. 博牌牌例",
    d8: "当庄和闲首轮开牌后，判断是否需要博牌。先判断闲，再判断庄。详见下表：",
    d9: "闲博牌",
    d10: "闲两牌合计点数",
    d11: "（闲家）",
    d12: "必须博牌",
    d13: "不得博牌",
    d14: "例牌，即定胜负",
    d15: "庄博牌",
    d16: "庄两牌合计点数",
    d18: "（庄家）",
    d19: "必须博牌",
    d20: "若闲家博得第三张牌的牌值为8点，庄家不得博牌",
    d21: "若闲家博得第三张牌的牌值为0，1，8，9点， 庄家不得博牌",
    d22: "若闲家博得第三张牌的牌值为0，1，2，3，8，9点， 庄家不得博牌",
    d23: "若闲家博得第三张牌的牌值为6 或 7点，庄家必须博牌",
    d24: "3. 点数计算",
    d25: "A-9点，根据其牌面数值为点数，其中A对应1点。",
    d26: "10或J或Q或K，为0点。",
    d27: "例：",
    d28: "4. 玩法判定",
    d29: "4.1. 庄&闲&和：",
    d30: "庄家点数大于闲家，则下注 “庄” 者获得奖励；",
    d31: "庄家：",
    d32: "闲家：",
    d33: "闲家点数大于庄家，则下注 “闲” 者获得奖励；",
    d34: "若庄闲点数相同，则下注 “和” 者获得奖励。",
    d35: "4.2. 庄对&闲对：",
    d36: "当前局中，庄或闲的前两张牌的牌面（仅数字或字母，不计花色）为对子；",
    d37: "庄/闲家：",
    d38: "庄家前两张为对子，为庄对。闲家前两张为对子，为闲对；",
    d39: "当出现对子，下注 “庄对” 或 “闲对” ，即可获得对应赔率的奖励。",
    d40: "4.3. 任意对子：",
    d41: "指庄或闲前两张牌为对子（详见庄对&闲对）;",
    d42: "无论出现庄对或闲对，下注 “任意对子” 者获得奖励。",
    d43: "4.4. 完美对子：",
    d44: "指庄或闲前两张牌为对子（详见庄对&闲对），同时满足花色相同条件；",
    d45: "无论庄或闲出现完美对子，下注 “完美对子” 者均可获得奖励。",
    d46: "4.5. 超级对：",
    d47: "首4张中包含两张或以上的相同牌面（不论花色）。",
    d48: "4.6. 龙7：",
    d49: "庄家以三张牌7点胜。",
    d50: "4.7. 熊猫8：",
    d51: "闲家以三张牌8点胜。",
    d52: "4.8. 大老虎&小老虎：",
    d53: "庄家以三张牌6点胜为大老虎；",
    d54: "庄家以两张牌6点胜为小老虎。",
    d55: "4.9. 老虎和：",
    d56: "庄闲分别以6点和局。",
    d57: "4.10. 老虎对：",
    d58: "根据庄闲前4张牌的结果，符合下面3种结果之一时，获得不同的赔率：",
    d59: "计庄对、闲对，且为相同牌面对子；",
    d60: "计庄对、闲对；",
    d61: "计庄对或闲对；",
    d62: "和局不退还下注。",
    d63: "4.11. 庄天牌&闲天牌：",
    d64: "庄家首两张合计为8或者9点，且庄胜，则下注 “庄天牌” 者获得奖励；",
    d65: "闲家首两张合计为8或者9点，且闲胜，则下注 “闲天牌” 者获得奖励。",
    d66: "4.12. 天牌：",
    d67: "庄家或闲家首两张牌合为8或者9点即可胜出。",
    d68: "4.13. 龙&虎&和：",
    d69: "庄家第一张牌点数，大于闲家第一张牌（10，J，Q，K分别为10，11，12，13点）为龙赢；",
    d70: "闲家第一张牌点数，大于庄家第一张牌（10，J，Q，K分别为10，11，12，13点）为虎赢；",
    d71: "庄闲家第一张牌点数相同（10，J，Q，K分别为10，11，12，13点）为龙虎和。",
    d72: "4.14. 超级六：",
    d73: "免佣和非免佣模式下都可押注；",
    d74: "当庄家未博牌，点数和个位为6，并且点数大过闲，则为12倍赔率；",
    d75: "当庄家博牌，其点数和个位为6，并且点数大过闲，则为20倍赔率。",
    d76: "4.15. 庄龙宝&闲龙宝：",
    d77: "庄以例牌胜出，则庄龙宝胜出；",
    d78: "闲以例牌胜出，则闲龙宝胜出；",
    d79: "庄非例牌，庄点数-闲点数=N，若N=4~9，则庄龙宝胜出；",
    d80: "闲非例牌，闲点数-庄点数=N，若N=4~9，则闲龙宝胜出；",
    d81: "例牌定义：首两牌结果为8或9点；",
    d82: "非例牌定义：例牌之外的所有情况，详见赔率表。",
    d83: "4.16. 超和：",
    d84: "庄闲为和的情况下，和的点数，包括 “和0~ 和9” 共十个超级和的点数；",
    d85: "例如：游戏中，庄家、闲家开牌结果均为0时，即押注 “超和0” 者为胜；",
    d86: "5. 赔率表",
    d87: "百家乐游戏中，提供百家乐玩法和免佣百家乐玩法。两种游戏规则一致，但投注庄的派彩方式有区别：",
    d88: "百家乐",
    d89: "投注项目",
    d90: "赔率",
    d91: "闲",
    d92: "1:1 <br> (开和局时退回下注金额)",
    d93: "庄",
    d94: "非免佣1:0.95 <br> (开和局时退回下注金额)",
    d95: "免佣1:1 <br> (如庄以6点取胜，则赔一半；开和局时退回下注金额)",
    d96: "和",
    d97: "闲对",
    d98: "庄对",
    d99: "超级对",
    d100: "完美对子",
    d101: "任意对子",
    d102: "龙7",
    d103: "熊猫8",
    d104: "大老虎",
    d105: "小老虎",
    d106: "老虎和",
    d107: "老虎对",
    d108: "1:100 <br> 计庄对、闲对，且为相同牌面对子 ",
    d109: "1:20 <br> 计庄对、闲对 ",
    d110: "1:4 <br>  计庄对或闲对 ",
    d111: "庄天牌&闲天牌",
    d112: "天牌",
    d113: "龙&虎",
    d114: "龙虎和时退回一半下注",
    d115: "龙虎和",
    d116: "超级六",
    d117: "1:12 <br> 庄以两张牌，6点胜出 ",
    d118: "1:20 <br> 庄以三张牌，6点胜出",
    d119: "龙宝",
    d120: "例牌和局,退回本金",
    d121: "退回本金",
    d122: "以例牌胜出",
    d123: "以非例牌赢4点",
    d124: "以非例牌赢5点",
    d125: "以非例牌赢6点",
    d126: "以非例牌赢7点",
    d127: "以非例牌赢8点",
    d128: "以非例牌赢9点",
    d129: "超和",
    d130: "超和（0）",
    d131: "超和（1）",
    d132: "超和（2）",
    d133: "超和（3）",
    d134: "超和（4）",
    d135: "超和（5）",
    d136: "超和（6）",
    d137: "超和（7）",
    d138: "超和（8）",
    d139: "超和（9）",
    d140: "6. 游戏特点",
    d141: "采用最得人心的8副牌（去掉大小王共416张）玩法。独有的博牌规则，正如人生，不计一时，只要投入，总有翻身机会。",
    d142: "7. 注意事项",
    d143: "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。",
    d144: "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。 ",
    d145: "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。 ",
    d146: "如遇结算错误，按照当期视频结果重新结算。",
    d147: "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。",
    d148: "游戏规则",
    d149: "博牌牌例",
    d150: "点数计算",
    d151: "玩法判定",
    d152: "赔率表",
    d153: "游戏特点",
    d154: "注意事项",
  },
  DragonTiger: {
    d1: "龙虎",
    d2: "1. 游戏规则",
    d3: "游戏采用8副牌（去大小王），共计416张牌；",
    d4: "只派2次牌，先龙后虎，各派1张，无需博牌；",
    d5: "开牌后按照两家牌点数比大小。 ",
    d6: "2. 点数计算",
    d7: "牌面A为1；",
    d8: "牌面2-10点数和牌面相同，即2-10；",
    d9: "J，Q，K分别为11，12，13。 ",
    d10: "3. 玩法判定",
    d11: "龙和虎比牌，龙大则龙赢，虎大则虎赢；",
    d12: "如龙虎点数相同，即为和局。",
    d13: "4. 赔率表",
    d14: " 投注项目 ",
    d15: "赔率",
    d16: "龙",
    d18: "1:1",
    d19: "开和局时，退回一半下注金额",
    d20: "虎",
    d21: "1:1",
    d22: "开和局时，退回一半下注金额",
    d23: "和",
    d24: "1:8",
    d25: "5. 游戏特点",
    d26: " 相对于百家乐，无需博牌，单张斗大。规则简单，上手容易，无论男女老幼均可快速上手体验游戏快乐。 ",
    d27: "6. 注意事项",
    d28: "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。",
    d29: "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。",
    d30: "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。",
    d31: "如遇结算错误，按照当期视频结果重新结算。",
    d32: "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。",
    d33: "游戏规则",
    d34: "点数计算",
    d35: "玩法判定",
    d36: "赔率表",
    d37: "游戏特点",
    d38: "注意事项",
  },
  Bulls: {
    d1: "牛牛",
    d2: "1. 游戏规则",
    d3: " 一副牌52张（去大小王）；",
    d4: " 发牌排序为庄→闲1→闲2→闲3循环，但需要用头牌决定先发哪家牌； ",
    d5: " 三家闲分别与庄家进行比牌，先比较牌型，若牌型相同，再比较各自最大一张牌的大小，若牌面相同，最后比较花色。 ",
    d6: "2. 点数计算",
    d7: " 牌面A为1；牌面10，J，Q，K为10点；牌面2-9点数和牌面相同； ",
    d8: " 每家有5张牌，存在三张相加为10的倍数，则为有牛。反之即是无牛； ",
    d9: " 牌型中有牛，剩下两张相加，个位数是1-9则为相应的牛几。为0即是牛牛； ",
    d10: " 如5张牌全是J/Q/K组成，则牌型为五公。 ",
    d11: "3. 玩法判定",
    d12: "牛牛中有庄，闲1，闲2，闲3四家牌；",
    d13: " 下注盘则分为闲1，闲2，闲3三门，每门包含庄平倍，庄翻倍，庄超倍，闲平倍，闲翻倍，闲超倍；",
    d14: " 开牌后，以庄和三家闲比大小。庄大押庄赢。闲大押闲赢； ",
    d15: " 牌型：五公＞牛牛＞牛9＞牛8＞牛7＞牛6＞牛5＞牛4＞牛3＞牛2＞牛1＞无牛； ",
    d16: " 大小：K＞Q＞J＞10＞9＞8＞7＞6＞5＞4＞3＞2＞A； ",
    d18: " 花色：黑桃＞红心＞梅花＞方块； ",
    d19: " 押平倍，输赢平倍。押翻倍，则输赢多倍。 ",
    d20: "4. 赔率表",
    d21: " 具体赔率倍数取决于赢家的牌型。押平倍输赢平倍，押翻倍输赢多倍。 ",
    d22: "玩家赢赔率",
    d23: "胜方牌型 ",
    d24: "平倍",
    d25: "翻倍",
    d26: "五公",
    d27: "牛牛",
    d28: "牛",
    d29: "无牛",
    d30: "玩家输赔率",
    d31: " 胜方牌型 ",
    d32: "平倍",
    d33: "翻倍",
    d34: "五公",
    d35: "牛牛",
    d36: "牛7-牛9",
    d37: "无牛-牛6",
    d38: " “翻倍”投注与派彩示例1: ",
    d39: " 押注对象 ",
    d40: "1家",
    d41: "投注玩法",
    d42: "闲翻倍",
    d43: "投注额度",
    d44: "100，实际扣除500，记录中将显示为“100（预扣400）”",
    d45: "比牌结果",
    d46: "庄家牌：牛8    |    1家牌：牛5",
    d47: "派彩",
    d48: "退回300（胜方牌型牛8/，对应玩家输的赔率2，1家输100×2=200）",
    d49: " “翻倍”投注与派彩示例2： ",
    d50: " 押注对象 ",
    d51: "1家",
    d52: "投注玩法",
    d53: "庄翻倍",
    d54: "投注额度",
    d55: "100，实际扣除500，记录中将显示为“100（预扣400）”",
    d56: "比牌结果",
    d57: "庄家牌：牛8    |    1家牌：牛5",
    d58: "派彩",
    d59: "690（退回500+100*胜方牌型牛8对应的玩家赢的赔率1.90=690）",
    d60: "5. 游戏特点",
    d61: " 流行于湖广一带的纸牌游戏。玩家可自由对庄闲下注。翻倍玩法会因胜方牌型而出现多种赔率，精彩刺激！ ",
    d62: "6. 注意事项",
    d63: "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。",
    d64: "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。",
    d65: "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。",
    d66: "如遇结算错误，按照当期视频结果重新结算。",
    d67: "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。",
    d68: "游戏规则",
    d69: "点数计算",
    d70: "玩法判定",
    d71: "赔率表",
    d72: "游戏特点",
    d73: "注意事项",
    d74: "超倍",
  },
  SanGong: {
    d1: "三公",
    d2: "1. 游戏规则",
    d3: "一副牌52张（去大小王）；",
    d4: "发牌排序为庄→闲1→闲2→闲3循环，但需要用头牌决定先发哪家牌；",
    d5: "三家闲和庄牌比大小，闲大则闲赢，庄大则闲输。",
    d6: "2. 牌型大小",
    d7: "游戏中，花牌J，Q，K为公牌",
    d8: "玩法",
    d9: "说明（牌型大小从大到小，从上到下排列）",
    d10: "三公",
    d11: "J，Q，K为公，三张牌全由公牌组成，即为三公",
    d12: "双公9",
    d13: "2张牌为公，另外1张为9点",
    d14: "单公9",
    d15: "1张牌为公，另外两张点数合计，个位数为9点",
    d16: "9点",
    d18: "3张牌点数合计，个位数为9点",
    d19: "双公8",
    d20: "2张牌为公，另外1张为8点",
    d21: "单公8",
    d22: "1张牌为公，另外两张点数合计，个位数为8点",
    d23: "8点",
    d24: "3张牌点数合计，个位数为8点",
    d25: "双公7",
    d26: "2张牌为公，另外1张为7点",
    d27: "单公7",
    d28: "1张牌为公，另外两张点数合计，个位数为7点",
    d29: "7点",
    d30: "3张牌点数合计，个位数为7点",
    d31: "双公6",
    d32: "2张牌为公，另外1张为6点",
    d33: "单公6",
    d34: "1张牌为公，另外两张点数合计，个位数为6点",
    d35: "6点",
    d36: "3张牌点数合计，个位数为6点",
    d37: "双公5",
    d38: "2张牌为公，另外1张为5点",
    d39: "单公5",
    d40: "1张牌为公，另外两张点数合计，个位数为5点",
    d41: "5点",
    d42: "3张牌点数合计，个位数为5点",
    d43: "双公4",
    d44: "2张牌为公，另外1张为4点",
    d45: "单公4",
    d46: "1张牌为公，另外两张点数合计，个位数为4点",
    d47: "4点",
    d48: "3张牌点数合计，个位数为4点",
    d49: "双公3",
    d50: "2张牌为公，另外1张为3点",
    d51: "单公3",
    d52: "1张牌为公，另外两张点数合计，个位数为3点",
    d53: "3点",
    d54: "3张牌点数合计，个位数为3点",
    d55: "双公2",
    d56: "2张牌为公，另外1张为2点",
    d57: "单公2",
    d58: "1张牌为公，另外两张点数合计，个位数为2点",
    d59: "2点",
    d60: "3张牌点数合计，个位数为2点",
    d61: "双公1",
    d62: "2张牌为公，另外1张为1点",
    d63: "单公1",
    d64: "1张牌为公，另外两张点数合计，个位数为1点",
    d65: "1点",
    d66: "3张牌点数合计，个位数为1点",
    d67: "双公0",
    d68: "2张牌为公，另外1张为0点",
    d69: "单公0",
    d70: "1张牌为公，另外两张点数合计，个位数为0点",
    d71: "0点",
    d72: "3张牌点数合计，个位数为0点",
    d73: "3. 对牌以上牌型",
    d74: "同花顺：",
    d75: "3张牌为连续且同花的牌，包含A，2，3和Q，K，A；",
    d76: "三条: ",
    d77: "3张牌为牌面相同的牌；",
    d78: "顺子: ",
    d79: "3张牌为连续，不计花色，包含A，2，3和Q，K，A；",
    d80: "同花: ",
    d81: "手牌中3张为同花，但面值不连续；",
    d82: "对子",
    d83: "手牌中3张其中两张牌面相同，即为对子。",
    d84: "3. 玩法判定",
    '三公中有庄，闲1，闲2，闲3四家牌；': '三公中有庄，闲1，闲2，闲3四家牌；',
    '下注盘则分为闲1，闲2，闲3三门，每门包含庄平倍，庄翻倍，庄超倍，闲平倍，闲翻倍，闲超倍；': '下注盘则分为闲1，闲2，闲3三门，每门包含庄平倍，庄翻倍，庄超倍，闲平倍，闲翻倍，闲超倍；',
    '开牌后，以庄和三家闲比大小。庄大押庄赢。闲大押闲赢；': '开牌后，以庄和三家闲比大小。庄大押庄赢。闲大押闲赢；',
    '大小：K＞Q＞J＞10＞9＞8＞7＞6＞5＞4＞3＞2＞A；': '大小：K＞Q＞J＞10＞9＞8＞7＞6＞5＞4＞3＞2＞A；',
    '花色：黑桃＞红心＞梅花＞方块；': '花色：黑桃＞红心＞梅花＞方块；',
    '押平倍，输赢平倍。押翻倍，则输赢多倍。押超倍，则输赢多倍。': '押平倍，输赢平倍。押翻倍，则输赢多倍。押超倍，则输赢多倍。',
    d91: "4. 赔率表",
    d92: "赔率分为庄闲输赢赔率，和特殊牌型赔率。",
    d93: "开牌结果",
    d94: "赔率",
    d95: "闲（赢）",
    d96: "1:1",
    d97: "闲（输）",
    d98: "1:1",
    d99: "和",
    d100: "三公",
    d101: "牌型",
    d102: "赔率",
    d103: "同花顺",
    d104: "三条",
    d105: "顺子",
    d106: "同花",
    d107: "对子",
    d108: "如牌型同时满足多个结果，则以赔率高的结果为准；",
    d109: "例如♠️2 ♠️3 ♠️4，同时满足同花顺和顺子，同花顺赔率更高，则以同花顺赔率派彩。",
    d110: "无抽水的设计，但下列两种状况有特殊赔率：",
    d111: "投注赢，闲家以6点的任意牌型组合胜出，则获1赔0.5；",
    d112: "投注输，庄家以6点的任意牌型组合胜出，则获1赔0.5。",
    d113: "5. 游戏特点",
    d114: "三公（3 Face）是一种传统民间玩法，下注简单，气氛热烈，不需要复杂操作和计算，拼的就是运气，在新兴的游戏类型中颇受欢迎。",
    d115: "6. 注意事项",
    d116: "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。",
    d117: "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。 ",
    d118: "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。 ",
    d119: "如遇结算错误，按照当期视频结果重新结算。",
    d120: "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。",
    d121: "游戏规则",
    d122: "牌型大小",
    d123: "对牌以上牌型",
    d124: "玩法判定",
    d125: "赔率表",
    d126: "游戏特点",
    d127: "注意事项",
    '玩家赢赔率':'玩家赢赔率',
    '玩家输赔率':'玩家输赔率',
    '胜方牌型':'胜方牌型',
    '平倍':'平倍',
    '翻倍':'翻倍',
    '超倍':'超倍',
    '三条':'三条',
    '3条3':'3条3',
    '点':'点',
  },
  // 龙凤炸金花
  GoldenFlower:{
    '龙凤炸金花':'龙凤炸金花',
    '游戏规则':'游戏规则',
    '使用一副牌游戏（去大小王）进行游戏；':'使用一副牌游戏（去大小王）进行游戏；',
    '先比较牌型大小，若牌型一致再按从大到小的顺序依次比较牌面大小，决定胜负。若龙、凤的三张牌大小一致，则为和局，退回玩家在本局下注龙、凤的筹码。':'先比较牌型大小，若牌型一致再按从大到小的顺序依次比较牌面大小，决定胜负。若龙、凤的三张牌大小一致，则为和局，退回玩家在本局下注龙、凤的筹码。',
    '玩法判定':'玩法判定',
    '牌型':'牌型',
    '豹子杀手：':'豹子杀手：',
    '三张非同花的2，3，5；':'三张非同花的2，3，5；',
    '豹子：':'豹子：',
    '三张点数相同的牌；':'三张点数相同的牌；',
    '同花顺：':'同花顺：',
    '花色相同的顺子牌；':'花色相同的顺子牌；',
    '同花：':'同花：',
    '花色相同的任意三张牌；':'花色相同的任意三张牌；',
    '顺子：':'顺子：',
    '三张连续的牌；':'三张连续的牌；',
    '对子：':'对子：',
    '两张点数相同的牌；':'两张点数相同的牌；',
    '散牌：':'散牌：',
    '无法组成任何类型的牌；':'无法组成任何类型的牌；',
    '牌型大小':'牌型大小',
    '豹子＞同花顺＞同花＞顺子＞对子＞散牌；':'豹子＞同花顺＞同花＞顺子＞对子＞散牌；',
    '豹子杀手＞豹子；':'豹子杀手＞豹子；',
    '当豹子牌和豹子杀手牌同时存在时，豹子杀手牌比豹子牌大；':'当豹子牌和豹子杀手牌同时存在时，豹子杀手牌比豹子牌大；',
    '散牌＞豹子杀手；':'散牌＞豹子杀手；',
    '如不存在豹子牌，豹子杀手是最小的散牌牌型。':'如不存在豹子牌，豹子杀手是最小的散牌牌型。',
    '对子大小':'对子大小',
    '如果两家都是对子牌，即比对对子点数大小，对A＞对K＞对Q＞对J＞对10＞对9＞对8＞对7＞对6＞对5＞对4＞对3＞对2；':' 如果两家都是对子牌，即比对对子点数大小，对A＞对K＞对Q＞对J＞对10＞对9＞对8＞对7＞对6＞对5＞对4＞对3＞对2；',
    '两对对子的点数相同，再比单牌点数大小。':'两对对子的点数相同，再比单牌点数大小。',
    '豹子、同花、散牌牌型':'豹子、同花、散牌牌型',
    '比最大那张牌的点数，如最大的那张点数相同，则比第二大牌的点数，以此类推；A>K>Q>J>10>9>8>7>6>5>4>3>2，花色不分大小。':'比最大那张牌的点数，如最大的那张点数相同，则比第二大牌的点数，以此类推；A>K>Q>J>10>9>8>7>6>5>4>3>2，花色不分大小。',
    '同花顺，顺子':'同花顺，顺子',
    '按照顺序比点数：AKQ>KQJ>......>432>32A':'按照顺序比点数：AKQ>KQJ>......>432>32A',
    '注意：只有在同花顺和顺子牌型中，A可作为最大或最小的牌，其余牌型中A为最大的单牌':'注意：只有在同花顺和顺子牌型中，A可作为最大或最小的牌，其余牌型中A为最大的单牌',
    '赔率表':'赔率表',
    '投注项目':'投注项目',
    '规则':'规则',
    '赔率':'赔率',
    '龙':'龙',
    '龙胜出':'龙胜出',
    '凤':'凤',
    '凤胜出':'凤胜出',
    '对8以上':'对8以上',
    '龙或凤以对8以上的牌型胜出':'龙或凤以对8以上的牌型胜出',
    '顺子':'顺子',
    '龙或凤以顺子的牌型胜出':'龙或凤以顺子的牌型胜出',
    '同花':'同花',
    '龙或凤以同花的牌型胜出':'龙或凤以同花的牌型胜出',
    '同花顺':'同花顺',
    '龙或凤以同花顺的牌型胜出':'龙或凤以同花顺的牌型胜出',
    '豹子':'豹子',
    '龙或凤以豹子的牌型胜出':'龙或凤以豹子的牌型胜出',
    '游戏特点':'游戏特点',
    '龙凤炸金花，也被称为赢三张。是一种广泛流传的民间游戏。最大特色除常规押注龙，凤。也可对赢家牌型进行投注。甚至任何对8以上牌型都为赢的玩法。可谓由简至繁，满足各阶层玩家。':'龙凤炸金花，也被称为赢三张。是一种广泛流传的民间游戏。最大特色除常规押注龙，凤。也可对赢家牌型进行投注。甚至任何对8以上牌型都为赢的玩法。可谓由简至繁，满足各阶层玩家。',
    '注意事项':'注意事项',
    '部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。':'部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。',
    '桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。':'桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。',
    '荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单':'荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。 ',
    '如遇结算错误，按照当期视频结果重新结算。':'如遇结算错误，按照当期视频结果重新结算。',
    '如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。':'如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。',
  },
  TexasHoldem:{
    '德州扑克':'德州扑克',
    '游戏规则':'游戏规则',
    '采用1副牌（去大小王），共计52张；':'采用1副牌（去大小王），共计52张；',
    '首先发出5张公共牌，然后发给闲家2张手牌，庄家2张手牌；':'首先发出5张公共牌，然后发给闲家2张手牌，庄家2张手牌；',
    '投注倒计时结束后，荷官会首先翻开闲家2张手牌和前3张公共牌；':'投注倒计时结束后，荷官会首先翻开闲家2张手牌和前3张公共牌；',
    '然后进入跟注阶段，闲家需要决定是否跟注；如果选择跟注，则需要在跟注区域投注相当于底注2倍的筹码。如果不跟注，则放弃本局，输掉底注。':'然后进入跟注阶段，闲家需要决定是否跟注；如果选择跟注，则需要在跟注区域投注相当于底注2倍的筹码。如果不跟注，则放弃本局，输掉底注。',
    '跟注环节结束后，荷官翻开后2张公共牌；':'跟注环节结束后，荷官翻开后2张公共牌；',
    '玩家通过自己的2张手牌和5张公共牌组成最大的5张牌，跟庄家比大小。':'玩家通过自己的2张手牌和5张公共牌组成最大的5张牌，跟庄家比大小。',
    'AA边注玩法':'AA边注玩法',
    'AA边注是可选的。可用于投注最先发的5张牌（玩家的两张牌和前三张公共牌）有没有一对A或更好的牌。':'AA边注是可选的。可用于投注最先发的5张牌（玩家的两张牌和前三张公共牌）有没有一对A或更好的牌。',
    '玩法判定':'玩法判定',
    '牌型':'牌型',
    '皇家同花顺':'皇家同花顺',
    '皇家同花顺是指包括A、K、Q、J、10这样大牌的同花顺。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。':'皇家同花顺是指包括A、K、Q、J、10这样大牌的同花顺。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。',
    '同花顺':'同花顺',
    '同花顺是指五张连续数值的同花牌，比如：9、8、7、6、5，而且全部是红心。两个同花顺相比，大牌面的胜出。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。':'同花顺是指五张连续数值的同花牌，比如：9、8、7、6、5，而且全部是红心。两个同花顺相比，大牌面的胜出。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。',
    '注：同花A、2、3、4、5为最小的同花顺；同花J、Q、K、A、2/Q、K、A、2、3/K、1、2、3、4不为同花顺。':'注：同花A、2、3、4、5为最小的同花顺；同花J、Q、K、A、2/Q、K、A、2、3/K、1、2、3、4不为同花顺。',
    '四条':'四条',
    '四条是指一手牌中包含四种花色同一数值的牌，再加上一张其他牌。比如说，您拿到了四种花色的A，那就是四条。大牌值的四条大于小牌值的四条。':'四条是指一手牌中包含四种花色同一数值的牌，再加上一张其他牌。比如说，您拿到了四种花色的A，那就是四条。大牌值的四条大于小牌值的四条。',
    '葫芦':'葫芦',
    '葫芦是指一手牌中有三种花色的同值牌，还有两种花色的另一同值牌，例如三张K和两张6。在两手葫芦牌中，三张同值牌面较大的一家胜出。':'葫芦是指一手牌中有三种花色的同值牌，还有两种花色的另一同值牌，例如三张K和两张6。在两手葫芦牌中，三张同值牌面较大的一家胜出。',
    '同花':'同花',
    '同花是指一手牌中的所有牌都是同一花色，但不是连续的，例如五张梅花。在两手同花中，根据每手牌中最大牌面的大小决定输赢。如果两手牌中最大牌面大小相同，那么以第二大牌面的大小决定输赢，以此类推直到分出输赢。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。':'同花是指一手牌中的所有牌都是同一花色，但不是连续的，例如五张梅花。在两手同花中，根据每手牌中最大牌面的大小决定输赢。如果两手牌中最大牌面大小相同，那么以第二大牌面的大小决定输赢，以此类推直到分出输赢。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。',
    '顺子':'顺子',
    '顺子是指一手牌中五张牌都是连续的，但不是同种花色，例如非同花9、8、7、6、5。两手顺子相比，大牌面的一手胜出。如果两手顺子牌面值相同，花色不作为决定输赢的条件；如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。':'顺子是指一手牌中五张牌都是连续的，但不是同种花色，例如非同花9、8、7、6、5。两手顺子相比，大牌面的一手胜出。如果两手顺子牌面值相同，花色不作为决定输赢的条件；如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。',
    '注：A、2、3、4、5为最小的顺子；J、Q、K、A、2/Q、K、A、2、3/K、1、2、3、4不为顺子。':'注：A、2、3、4、5为最小的顺子；J、Q、K、A、2/Q、K、A、2、3/K、1、2、3、4不为顺子。',
    '三条':'三条',
    '三条是指一手牌中包含三种花色的同值牌，还有两张非同值牌。比如说，如果玩家拿到了三条K，那就称之为三条。大牌面的三条胜出小牌面的三条。':'三条是指一手牌中包含三种花色的同值牌，还有两张非同值牌。比如说，如果玩家拿到了三条K，那就称之为三条。大牌面的三条胜出小牌面的三条。',
    '两对':'两对',
    '两对以下':'两对以下',
    '两对是指一手牌中包含两张同值牌，还有两张另一牌值的同值牌（牌面相等，但是与第一个对子牌面不相等），还有一张其他牌面的牌。一手牌中包含两张A和两张K就是两对。对于两手两对牌，比较各手牌中牌面较大的对子，大牌面的一手胜出。如果两手牌中较大牌面对子大小相同，则比较第二个对子。如果两个对子大小均相同看，则根据另外一张单牌决定输赢。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。':'两对是指一手牌中包含两张同值牌，还有两张另一牌值的同值牌（牌面相等，但是与第一个对子牌面不相等），还有一张其他牌面的牌。一手牌中包含两张A和两张K就是两对。对于两手两对牌，比较各手牌中牌面较大的对子，大牌面的一手胜出。如果两手牌中较大牌面对子大小相同，则比较第二个对子。如果两个对子大小均相同看，则根据另外一张单牌决定输赢。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。',
    '一对':'一对',
    '对子是指一手牌中包含两张同值牌（例如两张K），再加上三张其它非同值牌。牌值较大的对子胜过较小的对子。如果两手牌对子的牌面大小相同，则降序比较其它三张牌。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。':'对子是指一手牌中包含两张同值牌（例如两张K），再加上三张其它非同值牌。牌值较大的对子胜过较小的对子。如果两手牌对子的牌面大小相同，则降序比较其它三张牌。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。',
    '高牌':'高牌',
    '高牌是指不属于上述任何一种牌型的一手牌。不属于任何牌型。':'高牌是指不属于上述任何一种牌型的一手牌。不属于任何牌型。',
    '牌型大小':'牌型大小',
    '皇家同花顺＞同花顺＞四条＞葫芦＞同花＞顺子＞三条＞两对＞一对＞高牌':'皇家同花顺＞同花顺＞四条＞葫芦＞同花＞顺子＞三条＞两对＞一对＞高牌',
    '庄家最后的牌型必须为对4或以上，才有资格与闲家牌型比对；否则视为闲家赢。':'庄家最后的牌型必须为对4或以上，才有资格与闲家牌型比对；否则视为闲家赢。',
    '输赢判定和赔率表':'输赢判定和赔率表',
    '底注、跟注玩法赔率（不含本金）如下：':'底注、跟注玩法赔率（不含本金）如下：',
    '输赢':'输赢',
    '底注':'底注',
    '跟注':'跟注',
    '庄家没有资格(对4或以下),玩家胜':'庄家没有资格(对4或以下),玩家胜',
    '赔付表':'赔付表',
    '返回本金':'返回本金',
    '庄家有资格(对4或以上),玩家胜':'庄家有资格(对4或以上),玩家胜',
    '庄家有资格(对4或以上),平局':'庄家有资格(对4或以上),平局',
    '玩家不跟注(弃牌)':'玩家不跟注(弃牌)',
    '输':'输',
    '庄家有资格(对4或以上),庄家胜':'庄家有资格(对4或以上),庄家胜',
    '底注玩法赔率（不含本金）如下：':'底注玩法赔率（不含本金）如下：',
    '手牌':'手牌',
    '赔率':'赔率',
    'AA边注玩法赔率（不含本金）如下：':'AA边注玩法赔率（不含本金）如下：',
    '注意事项':'注意事项',
    '部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。':'部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。',
    '桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。':'桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。',
    '荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。':'荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。',
    '如遇结算错误，按照当期视频结果重新结算。':'如遇结算错误，按照当期视频结果重新结算。',
    '如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。':'如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。',
  },
  SicBo:{
    "骰宝":'骰宝',
    "游戏规则":'游戏规则',
    "荷官启动开关，机器产生3次或3次以上震动，透明骰蛊中三颗6面骰子将在震动作用下弹跳；":'荷官启动开关，机器产生3次或3次以上震动，透明骰蛊中三颗6面骰子将在震动作用下弹跳； ',
    "当停止震动，骰子落下时，取其上面显现的点数计数；":'当停止震动，骰子落下时，取其上面显现的点数计数；',
    "和三颗骰子所表现点数，组合，或和值相符合的玩法胜出；":'和三颗骰子所表现点数，组合，或和值相符合的玩法胜出；',
    "如震动停止后，三颗骰子有任意颗底面没有完全接触骰蛊最底面，或出现重叠状态，或震动次数少于3次，将会再次触发震动重新获取结果。":'如震动停止后，三颗骰子有任意颗底面没有完全接触骰蛊最底面，或出现重叠状态，或震动次数少于3次，将会再次触发震动重新获取结果。',
    "玩法判定":'玩法判定',
    "大/小":'大/小',
    "三颗骰子总点数11-17为大，4-10为小；":'三颗骰子总点数11-17为大，4-10为小；',
    "出现围骰，庄家通吃，大/小均输。":'出现围骰，庄家通吃，大/小均输。',
    "单/双":'单/双',
    "三颗骰子和值，分为单双两种情况；":'三颗骰子和值，分为单双两种情况；',
    "总点数为5，7，9，11，13，15，17为单；":'总点数为5，7，9，11，13，15，17为单；',
    "总点数为4，6，8，10，12，14，16为双；":'总点数为4，6，8，10，12，14，16为双；',
    "出现围骰，庄家通吃，单/双均输。":'出现围骰，庄家通吃，单/双均输。',
    "单点":'单点',
    "根据所选择点数相符的骰子出现次数判断；":'根据所选择点数相符的骰子出现次数判断；',
    "有一个骰子符合，则为单骰；":'有一个骰子符合，则为单骰；',
    "有两个骰子符合，则为双骰；":'有两个骰子符合，则为双骰；',
    "有三个骰子符合，则为三骰。":'有三个骰子符合，则为三骰。',
    "和值":'和值',
    "三个骰子的点数之和为和值；":'三个骰子的点数之和为和值；',
    "和值范围为4-17点。不同和值具有不同赔率（详见赔率）。":'和值范围为4-17点。不同和值具有不同赔率（详见赔率）。',
    "牌九式":'牌九式',
    "三颗骰子中，任意两颗骰子满足特定组合条件；":'三颗骰子中，任意两颗骰子满足特定组合条件；',
    "总共15种开出的三颗骰子任意两个满足即可胜出。":'总共15种开出的三颗骰子任意两个满足即可胜出。',
    "对子":'对子',
    "三颗骰子中，任意两颗骰子点数相同即为对子；":'三颗骰子中，任意两颗骰子点数相同即为对子；',
    "在6种对子玩法中押中，即可胜出。":'在6种对子玩法中押中，即可胜出。',
    "全围":'全围',
    "三颗骰子点数相同即全围；":'三颗骰子点数相同即全围；',
    "包含6种围骰组合，出现任意一种即可胜出。":'包含6种围骰组合，出现任意一种即可胜出。',
    "围骰":'围骰',
    "投注特定的一种围骰，即三颗骰子点数完全一样。满足条件即可胜出。":'投注特定的一种围骰，即三颗骰子点数完全一样。满足条件即可胜出。',
    "赔率表":'赔率表',
    "玩法赔率":'玩法赔率',
    "投注项目":'投注项目',
    "赔率":'赔率',
    "大":'大',
    "小":'小',
    "单骰":'单骰',
    "双骰":'双骰',
    "三骰":'三骰',
    "对子（双骰、长牌）":'对子（双骰、长牌）',
    "牌九式（骨牌、短牌）":'牌九式（骨牌、短牌）',
    "点数和为4或17":'点数和为4或17',
    "点数和为5或16":'点数和为5或16',
    "点数和为6或15":'点数和为6或15',
    "点数和为7或14":'点数和为7或14',
    "点数和为8或13":'点数和为8或13',
    "点数和为9、10、11或12":'点数和为9、10、11或12',
    "单":'单',
    "双":'双',
    "游戏特点":'游戏特点',
    "是古老的中国骰子游戏，玩家通过猜测骰子开出的点数，总和，组合等，具备多元化投注种类，以及极具吸引力的赔率，极受中国乃至亚洲玩家欢迎。":'是古老的中国骰子游戏，玩家通过猜测骰子开出的点数，总和，组合等，具备多元化投注种类，以及极具吸引力的赔率，极受中国乃至亚洲玩家欢迎。',
    "注意事项":'注意事项',
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。":'部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。',
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。":'桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。',
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。":'荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。',
    "如遇结算错误，按照当期视频结果重新结算。":'如遇结算错误，按照当期视频结果重新结算。',
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。":'如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。',
  },
  SeDie:{
    '色碟':'色碟',
    '游戏规则':'游戏规则',
    '荷官将4颗红白双色的钮扣放入碟中，盖上碗后上下摇碟；完成摇碟动作后静置于画面中供会员下注。下注时间结束将碗打开，依开出结果派彩。':'荷官将4颗红白双色的钮扣放入碟中，盖上碗后上下摇碟；完成摇碟动作后静置于画面中供会员下注。下注时间结束将碗打开，依开出结果派彩。',
    '玩法说明':'玩法说明',
    '大：开出(4个红色)、(3个红色，1个白色钮扣)属「大」。二红二白视为和局，退回玩家下注筹码':'大：开出(4个红色)、(3个红色，1个白色钮扣)属「大」。二红二白视为和局，退回玩家下注筹码',
    '小：开出(4个白色)、(3个白色，1个红色钮扣)属「小」。二红二白视为平手，退回玩家下注筹码':'小：开出(4个白色)、(3个白色，1个红色钮扣)属「小」。二红二白视为平手，退回玩家下注筹码',
    '单：开出(3个红色，1个白色钮扣)、(3个白色，1个红色钮扣)属「单」；':'单：开出(3个红色，1个白色钮扣)、(3个白色，1个红色钮扣)属「单」；',
    '双：开出(4个红色钮扣)、(4个白色钮扣)、(2个白色、2个红色钮扣)属「双」；':'双：开出(4个红色钮扣)、(4个白色钮扣)、(2个白色、2个红色钮扣)属「双」；',
    '4（4红）：开出4个红色钮扣。':'4（4红）：开出4个红色钮扣。',
    '0（4白)：开出4个白色钮扣。':'0（4白)：开出4个白色钮扣。',
    '3(3红1白)：开出3个红色，1个白色钮扣。':'3(3红1白)：开出3个红色，1个白色钮扣。',
    '1(3白1红)：开出3个白色，1个红色钮扣。':'1(3白1红)：开出3个白色，1个红色钮扣。',
    '赔率表':'赔率表',
    '玩法赔率':'玩法赔率',
    '下注组合':'下注组合',
    '赔率':'赔率',
    '大':'大',
    '小':'小',
    '单':'单',
    '双':'双',
    '4(4红)':'4(4红)',
    '0(4白)':'0(4白)',
    '3(3红1白)':'3(3红1白)',
    '1(3白1红)':'1(3白1红)',
    '特别说明':'特别说明',
    '本游戏进行方式为最接近实境赌场之设置，若有发生特殊情形将依本公司公告之办法处理。':'本游戏进行方式为最接近实境赌场之设置，若有发生特殊情形将依本公司公告之办法处理。',
    '荷官在游戏进行中若不慎让碗、碟或钮扣掉落，将物件放回定位后，重新进行摇碟。':'荷官在游戏进行中若不慎让碗、碟或钮扣掉落，将物件放回定位后，重新进行摇碟。',
    '若开出结果时有钮扣重叠无法判定结果时，荷官将用玻璃棒分开重叠的钮扣。':'若开出结果时有钮扣重叠无法判定结果时，荷官将用玻璃棒分开重叠的钮扣。',
    '荷官若没有摇碟就开碟，或在下注时间尚未结束前即开碟，该局将判定为无效，并退还所有下注本金。':'荷官若没有摇碟就开碟，或在下注时间尚未结束前即开碟，该局将判定为无效，并退还所有下注本金。',
    '荷官摇碟后开碟过程:如出现【开碟中的碗触碰到钮扣】，最终以开碟后钮扣倒下的颜色为最终结果。':'荷官摇碟后开碟过程:如出现【开碟中的碗触碰到钮扣】，最终以开碟后钮扣倒下的颜色为最终结果。',
    '注意事项':'注意事项',
    '桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。':'桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。',
    '荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。':'荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。',
    '如遇结算错误，按照当期视频结果重新结算。':'如遇结算错误，按照当期视频结果重新结算。',
    '如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。':'如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。',
  },

   // 安达巴哈
   AndarBahar: {
    '安达巴哈': '安达巴哈',
    '游戏规则': '游戏规则',
    '安达巴哈是一款非常简单的纸牌游戏，该游戏使用1副牌（去大小王）；': '安达巴哈是一款非常简单的纸牌游戏，该游戏使用1副牌（去大小王）；',
    '荷官在小丑牌位置上发一张牌，牌面朝上，然后继续在A（安达）和B（巴哈）点上发牌，牌面朝上；': '荷官在小丑牌位置上发一张牌，牌面朝上，然后继续在A（安达）和B（巴哈）点上发牌，牌面朝上；',
    '出现一张牌和小丑牌的面值相同时，游戏结束。': '出现一张牌和小丑牌的面值相同时，游戏结束。',
    '玩法判定': '玩法判定',
    '安达或巴哈哪家先发出和小丑牌面值相同时，哪家获胜。': '安达或巴哈哪家先发出和小丑牌面值相同时，哪家获胜。',
    '赔率表': '赔率表',
    '玩法赔率': '玩法赔率',
    '投注项目': '投注项目',
    '赔率': '赔率',
    '安达': '安达',
    '巴哈': '巴哈',
    '游戏特点': '游戏特点',
    '安达巴哈是印度最受欢迎的纸牌游戏之一，它的流行在于它的简单性，您只有两种玩法选择，投注简单快速。': '安达巴哈是印度最受欢迎的纸牌游戏之一，它的流行在于它的简单性，您只有两种玩法选择，投注简单快速。',
    '注意事项': '注意事项',
    '桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。': '桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。',
    '荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。': '荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。',
    '如遇结算错误，按照当期视频结果重新结算。': '如遇结算错误，按照当期视频结果重新结算。',
    '如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。': '如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。'
  },

    // 21点
    Blackjack: {
      '21点': '21点',
      '游戏简述': '游戏简述',
      '21点又名黑杰克（Blackjack），起源于法国，已流传到世界各地，有着悠久的历史。该游戏使用除大小王之外的52张牌，游戏者的目标是使手中的牌的点数之和不超过21点且尽量大，然后和荷官比大小。': '21点又名黑杰克（Blackjack），起源于法国，已流传到世界各地，有着悠久的历史。该游戏使用除大小王之外的52张牌，游戏者的目标是使手中的牌的点数之和不超过21点且尽量大，然后和荷官比大小。',
      '玩法规则': '玩法规则',
      '游戏规则': '游戏规则',
      '7个主玩家坐在赌桌上（可有无限数量的旁注玩家）。21点的目标是让您的牌比庄家的牌更接近21点，但不能超过21点。在21点中，A可以算作1，也可以算作11，花牌（J、Q、K）算10，数字牌按照面值算。Blackjack比21点大。': '7个主玩家坐在赌桌上（可有无限数量的旁注玩家）。21点的目标是让您的牌比庄家的牌更接近21点，但不能超过21点。在21点中，A可以算作1，也可以算作11，花牌（J、Q、K）算10，数字牌按照面值算。Blackjack比21点大。',
      '如果您的头两张牌是一张A和一张点数为10的牌，则您获得了Blackjack，此时如果庄家不是Blackjack,您将赢得自己赌注的1.5倍。（如您的赌注是10，则您会收到25）。': '如果您的头两张牌是一张A和一张点数为10的牌，则您获得了Blackjack，此时如果庄家不是Blackjack,您将赢得自己赌注的1.5倍。（如您的赌注是10，则您会收到25）。',
      '出现一张牌和小丑牌的面值相同时，游戏结束。': '出现一张牌和小丑牌的面值相同时，游戏结束。',
      '玩法判定': '玩法判定',
      '如果您的牌的总点数比庄家的更接近21点，您将赢得您的赌注数额（如果您的赌注是10，您则收到20）。': '如果您的牌的总点数比庄家的更接近21点，您将赢得您的赌注数额（如果您的赌注是10，您则收到20）。',
      '如果您的牌的总点数超过21点，您“爆牌”并失去赌注。': '如果您的牌的总点数超过21点，您“爆牌”并失去赌注。',
      '如果您和庄家的总点数相同（17点及以上），则双方都不算赢，您的赌注也会退回，算作平手。': '如果您和庄家的总点数相同（17点及以上），则双方都不算赢，您的赌注也会退回，算作平手。',
      '庄家拿牌到16点，在点数达到或超过17后才能停牌（包括软17）。': '庄家拿牌到16点，在点数达到或超过17后才能停牌（包括软17）。',
      '游戏流程': '游戏流程',
      '投注主赌注后，可以投注边注玩法（完美对子、21+3）。': '投注主赌注后，可以投注边注玩法（完美对子、21+3）。',
      '投注轮结束后，使用要牌、停牌、加倍、分牌和保险（如果庄家的第一张牌是A）按钮来参与游戏。': '投注轮结束后，使用要牌、停牌、加倍、分牌和保险（如果庄家的第一张牌是A）按钮来参与游戏。',
      '如果您没有选择操作，您将会自动停牌。如果您选择加倍，则会从余额中拿出与主赌注相等的金额并将您的主赌注翻倍。': '如果您没有选择操作，您将会自动停牌。如果您选择加倍，则会从余额中拿出与主赌注相等的金额并将您的主赌注翻倍。',
  
      '要牌': '要牌',
      '选择“要牌”继续游戏并再发一张牌。': '选择“要牌”继续游戏并再发一张牌。',
      '停牌': '停牌',
      '选择“停牌”则该手牌的当前回合结束。': '选择“停牌”则该手牌的当前回合结束。',
      '分牌': '分牌',
      '如果您的前两张牌点数相同（注：10、J、Q、K均为10点），您可将其分成两手独立的牌，并投放与原赌注相同的第二份赌注。然后您可为每手牌分别要牌。分牌后不能再次分牌。': '如果您的前两张牌点数相同（注：10、J、Q、K均为10点），您可将其分成两手独立的牌，并投放与原赌注相同的第二份赌注。然后您可为每手牌分别要牌。分牌后不能再次分牌。',
      '对于分出的每手牌，您都可以要任意数目的牌；但如果将两张A分牌，您只能为每张A再拿一张牌。如果您在分牌后得到一张A和一张点数为十的牌，那么这样的牌只能算作21点，而不算作Blackjack。这种情况下的赔付是1：1,而不是1：1.5。': '对于分出的每手牌，您都可以要任意数目的牌；但如果将两张A分牌，您只能为每张A再拿一张牌。如果您在分牌后得到一张A和一张点数为十的牌，那么这样的牌只能算作21点，而不算作Blackjack。这种情况下的赔付是1：1,而不是1：1.5。',
      '加倍': '加倍',
      '在获发前两张牌后，如果您认为靠第三张牌可以赢过庄家的牌，则您可以将赌注加倍。赌注将变成原赌注的两倍（差额从您的余额中扣除），并且您将再获发一张牌。': '在获发前两张牌后，如果您认为靠第三张牌可以赢过庄家的牌，则您可以将赌注加倍。赌注将变成原赌注的两倍（差额从您的余额中扣除），并且您将再获发一张牌。',
      '您可以在分牌后加倍。': '您可以在分牌后加倍。',
      '如果玩家加倍，庄家的第一张牌点数是A,且庄家获得Blackjack，则您将输掉两份赌注（初始赌注和加倍赌注）。': '如果玩家加倍，庄家的第一张牌点数是A,且庄家获得Blackjack，则您将输掉两份赌注（初始赌注和加倍赌注）。',
      '保险': '保险',
      '如果庄家的初始牌为A，您将可以投注保险，以防庄家获得Blackjack。保险投注金额为底注的一半。': '如果庄家的初始牌为A，您将可以投注保险，以防庄家获得Blackjack。保险投注金额为底注的一半。',
      '如果庄家的确有Blackjack，您将按1：2获得保险赔付。': '如果庄家的确有Blackjack，您将按1：2获得保险赔付。',
      '如果庄家没拿到Blackjack，无论您最终输或者赢，您将输掉自己的保险投注。': '如果庄家没拿到Blackjack，无论您最终输或者赢，您将输掉自己的保险投注。',
      '10牌自动胜': '10牌自动胜',
      '理论上，您有可能要10张牌但仍未爆牌(一副手牌）。这种情况下，您的手牌将自动获胜，但庄家拿到Blackjack时除外。': '理论上，您有可能要10张牌但仍未爆牌(一副手牌）。这种情况下，您的手牌将自动获胜，但庄家拿到Blackjack时除外。',

      '旁注功能（座位后投注）': '旁注功能（座位后投注）',
      '本游戏提供旁注功能。这样您可以参与游戏，只需跟随主玩家的操作，对他们的手牌下注。': '本游戏提供旁注功能。这样您可以参与游戏，只需跟随主玩家的操作，对他们的手牌下注。',
      '只有被主玩家占据的位置上才有旁注选项。您可以同时成为多个位置上的旁注玩家。作为主玩家参与游戏时，您也可以在除自己位置外的所有其他位置上作为旁注玩家投注。': '只有被主玩家占据的位置上才有旁注选项。您可以同时成为多个位置上的旁注玩家。作为主玩家参与游戏时，您也可以在除自己位置外的所有其他位置上作为旁注玩家投注。',
      '作为旁注玩家，您会自动跟随主玩家的操作，除非他们选择分牌或加倍，而您没有足够的资金这样做。这种情况下，如果主玩家加倍，您会拿牌并停牌；如果主玩家分牌，则按右主手牌操作。': '作为旁注玩家，您会自动跟随主玩家的操作，除非他们选择分牌或加倍，而您没有足够的资金这样做。这种情况下，如果主玩家加倍，您会拿牌并停牌；如果主玩家分牌，则按右主手牌操作。',
      '如果您对一个玩家的手牌进行旁注，但该玩家在投注轮决定不下注，您的赌注将返还到您的账户。': '如果您对一个玩家的手牌进行旁注，但该玩家在投注轮决定不下注，您的赌注将返还到您的账户。',
   
      '边注': '边注',
      '这些是主游戏之外的玩法，必须投注主赌注后才可投注这些玩法。': '这些是主游戏之外的玩法，必须投注主赌注后才可投注这些玩法。',
      '21+3': '21+3',
      '玩家可以对发给玩家的前两张牌连同发给庄家的第一张牌下注。': '玩家可以对发给玩家的前两张牌连同发给庄家的第一张牌下注。',
      '同花三条：所有三张牌的点数和花色都相同': '同花三条：所有三张牌的点数和花色都相同',
      '同花顺：所有三张牌花色相同、点数依次排列': '同花顺：所有三张牌花色相同、点数依次排列',
      '三条：所有三张牌大小相同': '三条：所有三张牌大小相同',
      '顺子：所有三张牌花色不同但点数依次排列': '顺子：所有三张牌花色不同但点数依次排列',
      '同花：所有三张牌花色相同': '同花：所有三张牌花色相同',
  
      '同花三条': '同花三条',
      '(完全相同的三张牌)赔率 1：100': '(完全相同的三张牌)赔率 1：100',
      '同花顺': '同花顺',
      '（同花顺子,三张同花顺序）赔率 1：40': '（同花顺子,三张同花顺序）赔率 1：40',
      '三条': '三条',
      '（三张相同牌值的非同花牌）赔率 1：30': '（三张相同牌值的非同花牌）赔率 1：30',
      '顺子': '顺子',
      '（非同顺子,三张不同花顺序,包含Q、K、A牌型）赔率 1：10': '（非同顺子,三张不同花顺序,包含Q、K、A牌型）赔率 1：10',
      '同花': '同花',
      '（三张同一花色的牌）赔率 1：5': '（三张同一花色的牌）赔率 1：5',
  
      '以玩家首2张牌算': '以玩家首2张牌算',
      '完美对子': '完美对子',
      '（闲家一对同色同花同点值）赔率 1：25': '（闲家一对同色同花同点值）赔率 1：25',
      '同色对子': '同色对子',
      '（闲家一对同色同点值不同花）赔率 1：12': '（闲家一对同色同点值不同花）赔率 1：12',
      '混合对子': '混合对子',
      '（闲家一对不同色不同花同点值）赔率 1：6': '（闲家一对不同色不同花同点值）赔率 1：6',
  
      '赔率表': '赔率表',
      '玩法赔率': '玩法赔率',
      '主游戏': '主游戏',
      '赔率': '赔率',
      '投注项目': '投注项目',
      '主赌注': '主赌注',
      'Blackjack': 'Blackjack',
      '21+3边注玩法': '21+3边注玩法',
      '完美对子边注玩法': '完美对子边注玩法',
  
      '注意事项': '注意事项',
      '部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。': '部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。',
      '所有入座玩家必须先下注某个位置的底注才能下注该位置的完美对子和21+3玩法。': '所有入座玩家必须先下注某个位置的底注才能下注该位置的完美对子和21+3玩法。',
      '如果赌桌上的每位玩家均爆牌（点数高于21点），那么这局游戏结束；庄家不需要拿牌。如果赌桌上的每位玩家均爆牌（点数高于21点），但庄家底牌为A,庄家需要拿1牌，以判断庄家是否为BJ。': '如果赌桌上的每位玩家均爆牌（点数高于21点），那么这局游戏结束；庄家不需要拿牌。如果赌桌上的每位玩家均爆牌（点数高于21点），但庄家底牌为A,庄家需要拿1牌，以判断庄家是否为BJ。',
      '桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。': '桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。',
      '荷官发牌时，偶有系统无法判读状况时，此时荷官将重新扫描，直到系统能完整判读（如遇故障将取消所有相关注单/派彩）。': '荷官发牌时，偶有系统无法判读状况时，此时荷官将重新扫描，直到系统能完整判读（如遇故障将取消所有相关注单/派彩）。',
      '如遇结算错误，按照当期视频结果重新结算。': '如遇结算错误，按照当期视频结果重新结算。',
      '如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。': '如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。',
      '如果您从游戏中断开，任何已确定赌注将保持有效，并在您不在场的时候结算。重新连接后，您可以在“投注记录”中查看结果。': '如果您从游戏中断开，任何已确定赌注将保持有效，并在您不在场的时候结算。重新连接后，您可以在“投注记录”中查看结果。'
    },
  Roulette:{
    "轮盘":'轮盘',
    "游戏规则":'游戏规则',
    "荷官转动轮盘，并抛出象牙制滚球到轮盘外侧；":'荷官转动轮盘，并抛出象牙制滚球到轮盘外侧；',
    "当滚球在轮盘内转动多周后减速并落入下方格子；":'当滚球在轮盘内转动多周后减速并落入下方格子；',
    "该格子所对应的数字，作为该局结果；":'该格子所对应的数字，作为该局结果；',
    "若滚球在轮盘转动小于或等于5圈，需要重新打球。":'若滚球在轮盘转动小于或等于5圈，需要重新打球。',
    "玩法判定":'玩法判定',
    "美式注盘":'美式注盘',
    "美式下注盘也叫经典盘，包含了直注以及各种组合下注。玩家可在游戏中切换美式和法式下注盘。":'美式下注盘也叫经典盘，包含了直注以及各种组合下注。玩家可在游戏中切换美式和法式下注盘。',
    "美式-直注":'美式-直注',
    "即直接点击数字方块，对0-36的数字直接下注。":'即直接点击数字方块，对0-36的数字直接下注。',
    "例如，下注11点，只有当结果是11时才会胜出。":'例如，下注11点，只有当结果是11时才会胜出。',
    "美式-分注":'美式-分注',
    "即在线上下注，当结果为线两边的数字时胜出。":'即在线上下注，当结果为线两边的数字时胜出。',
    "例如，下注3和6之间的线，或者2和3之间的线。":'例如，下注3和6之间的线，或者2和3之间的线。',
    "美式-三数":'美式-三数',
    "三数为同时投注包含0在内的三个数字。":'三数为同时投注包含0在内的三个数字。',
    "例如，0，1，2为一组。0，2，3为一组。":'例如，0，1，2为一组。0，2，3为一组。',
    "美式-四号":'美式-四号',
    "同时对0，1，2，3四个数字进行下注。":'同时对0，1，2，3四个数字进行下注。',
    "美式-角注":'美式-角注',
    "对4个数字方框交叉处点击。":'对4个数字方框交叉处点击。',
    "例如：1，2，4，5为一注。2，3，5，6为一注。":'例如：1，2，4，5为一注。2，3，5，6为一注。',
    "美式-街注":'美式-街注',
    "对3个相连的数字投注。":'对3个相连的数字投注。',
    "例如：1，2，3为一组。7，8，9为一组。":'例如：1，2，3为一组。7，8，9为一组。',
    "美式-线注":'美式-线注',
    "类似街注，区别在于对2个街注相交的位置下注，同时下注6个数字。":'类似街注，区别在于对2个街注相交的位置下注，同时下注6个数字。',
    "例如：1，2，3，4，5，6一注。4，5，6，7，8，9一注。":'例如：1，2，3，4，5，6一注。4，5，6，7，8，9一注。',
    "美式-列注":'美式-列注',
    "每列12个号码，对“第X列”处下注。":'每列12个号码，对“第X列”处下注。',
    "例如：第一列包括1，4，7，10，13，16，19，22，25，28，31，34，共12个数字。":'例如：第一列包括1，4，7，10，13，16，19，22，25，28，31，34，共12个数字。',
    "美式-打注":'美式-打注',
    "将筹码下到“第X打”的位置。":'将筹码下到“第X打”的位置。',
    "例如：第一打包括1-12。第二打为13-24。第三打为25-36。":'例如：第一打包括1-12。第二打为13-24。第三打为25-36。',
    "美式-红黑":'美式-红黑',
    "对桌台上标注红，黑的位置下注。若开0则通杀，红/黑均输。":'对桌台上标注红，黑的位置下注。若开0则通杀，红/黑均输。',
    "例如：所有为红色格子的数字或所有黑色格子的数字。":'例如：所有为红色格子的数字或所有黑色格子的数字。',
    "美式-单双":'美式-单双',
    "对桌台上标注单，双的位置下注。若开0则通杀，单/双均输。":'对桌台上标注单，双的位置下注。若开0则通杀，单/双均输。',
    "例如：单数包括1，3，5……35的单数。双则表示2，4，6……36的双数。":'例如：单数包括1，3，5……35的单数。双则表示2，4，6……36的双数。',
    "美式-大小":'美式-大小',
    "对桌台上标注大，小的位置下注。若开0则通杀，大/小均输。":'对桌台上标注大，小的位置下注。若开0则通杀，大/小均输。',
    "例如：小（1-18）。大（19-36）。":'例如：小（1-18）。大（19-36）。',
    "法式注盘":'法式注盘',
    "也叫欧式注盘，可视为对美式注盘的衍生。将美式注盘的一部分打包组合后一起下注的玩法。":'也叫欧式注盘，可视为对美式注盘的衍生。将美式注盘的一部分打包组合后一起下注的玩法。',
    "法式-相邻投注：":'法式-相邻投注：',
    "点击法式轮盘的格子，同时对点击格子左邻和右邻两个号码（一共5号码），按顺序各下一注。":'点击法式轮盘的格子，同时对点击格子左邻和右邻两个号码（一共5号码），按顺序各下一注。',
    "例如：选中5，则投注号码为23，10，5，24，16。":'例如：选中5，则投注号码为23，10，5，24，16。',
    "法式-轮上零旁：":'法式-轮上零旁：',
    "点击轮上零旁区域。":'点击轮上零旁区域。',
    "对0和3；12和15；32和35；26；各下一注。":'对0和3；12和15；32和35；26；各下一注。',
    "法式-零旁注上角：":'法式-零旁注上角：',
    "点击零旁注上角区域，对以下区域和组合进行下注。":'点击零旁注上角区域，对以下区域和组合进行下注。',
    "4和7；12和15；18和21；19和22；32和35；5个组合各一注；":'4和7；12和15；18和21；19和22；32和35；5个组合各一注；',
    "0，2，3的组合，下两注；":'0，2，3的组合，下两注；',
    "25，26，28，29的组合，下两注。":'25，26，28，29的组合，下两注。',
    "法式-轮上孤注：":'法式-轮上孤注：',
    "点击轮盘孤注，对以下区域组合进行下注。":'点击轮盘孤注，对以下区域组合进行下注。',
    "即轮盘枱上的1号，6号，9号；14号，17号；17号，20号；31号，和34号几种组合各投1注。":'即轮盘枱上的1号，6号，9号；14号，17号；17号，20号；31号，和34号几种组合各投1注。',
    "法式-轮盘下角注：":'法式-轮盘下角注：',
    "法式-轮盘下角注。":'法式-轮盘下角注。',
    "5和8；10和11；13和16；23和24；27和30；33和36这六个玩法下1注。":'5和8；10和11；13和16；23和24；27和30；33和36这六个玩法下1注。',
    "小球落入的格子对应信息包含数字，红黑。数字分大小，单双；":'小球落入的格子对应信息包含数字，红黑。数字分大小，单双；',
    "同时根据数字在下注盘的位置，分为“打”和“列”；":'同时根据数字在下注盘的位置，分为“打”和“列”；',
    "也有根据数字所在下注盘位置，分为不同组的玩法。":'也有根据数字所在下注盘位置，分为不同组的玩法。',
    "赔率表":'赔率表',
    "由于法式下注盘只是对美式下注盘的玩法打包，其赔率对应到美式下注盘，因此赔率以美式为主。":'由于法式下注盘只是对美式下注盘的玩法打包，其赔率对应到美式下注盘，因此赔率以美式为主。',
    "投注项目":'投注项目',
    "赔率":'赔率',
    "直注":'直注',
    "分注":'分注',
    "街注":'街注',
    "三数":'三数',
    "角注":'角注',
    "四号":'四号',
    "线注":'线注',
    "列注":'列注',
    "打注":'打注',
    "红/黑":'红/黑',
    "单/双":'单/双',
    "大/小":'大/小',
    "游戏特点":'游戏特点',
    "欧美常见的竞猜游戏，一度风靡于皇族和贵族之间，现代社会才步入平民娱乐。被誉为最高贵的游戏。以下注玩法多维度著称，单押直注追求高赔率，或多点投入追求中奖率，休闲与刺激兼顾。":'欧美常见的竞猜游戏，一度风靡于皇族和贵族之间，现代社会才步入平民娱乐。被誉为最高贵的游戏。以下注玩法多维度著称，单押直注追求高赔率，或多点投入追求中奖率，休闲与刺激兼顾。',
    "注意事项":'注意事项',
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。":'部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。',
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。":'桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。',
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。":'荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。',
    "如遇结算错误，按照当期视频结果重新结算。":'如遇结算错误，按照当期视频结果重新结算。',
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。":'如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。',
  },
  Fantan:{
    "番摊":'番摊',
    "游戏规则":'游戏规则',
    "游戏使用钮扣200颗，新局开始后，荷官用铁碗从钮扣堆中取出不知数的钮扣，开始下注。":'游戏使用钮扣200颗，新局开始后，荷官用铁碗从钮扣堆中取出不知数的钮扣，开始下注。',
    "下注结束后，荷官即翻开铁碗收皮，用一个棒子将钮扣每4个为一列排好，直到剩下4或少于4个为止。":'下注结束后，荷官即翻开铁碗收皮，用一个棒子将钮扣每4个为一列排好，直到剩下4或少于4个为止。',
    "最后剩下的钮扣数即为本局开奖结果，画面会显示玩家的输赢情况。":'最后剩下的钮扣数即为本局开奖结果，画面会显示玩家的输赢情况。',
    "玩法判定":'玩法判定',
    "单：1、3为单；":'单：1、3为单；',
    "双：2、4为双。":'双：2、4为双。',
    "番：投注单一号码，开出为赢，其余为输。如：1番，开1为赢，2、3、4为输；":'番：投注单一号码，开出为赢，其余为输。如：1番，开1为赢，2、3、4为输；',
    "念：投注两个号码，一个为赢，一个为和，另外两个为输 如：1念2，开1为赢，开2为和，3、4为输；":'念：投注两个号码，一个为赢，一个为和，另外两个为输 如：1念2，开1为赢，开2为和，3、4为输；',
    "角：投注两个号码，开出为赢，其余为输。如：1.2角，开1、2为赢，3、4为输；":'角：投注两个号码，开出为赢，其余为输。如：1.2角，开1、2为赢，3、4为输；',
    "通：投注两个号码，开出为赢，其余两个号码一输一和。如：12三通，则开1、2为赢，3为输，4为和；":'通：投注两个号码，开出为赢，其余两个号码一输一和。如：12三通，则开1、2为赢，3为输，4为和；',
    "三门：投注三个号码，如果开出的结果是三个号码之一，则中奖；其他则输。如：三门123，开1、2、3为赢，4为输；":'三门：投注三个号码，如果开出的结果是三个号码之一，则中奖；其他则输。如：三门123，开1、2、3为赢，4为输；',
    "赔率表":'赔率表',
    "玩法赔率":'玩法赔率',
    "投注项目":'投注项目',
    "赔率":'赔率',
    "单":'单',
    "双":'双',
    "番":'番',
    "念":'念',
    "角":'角',
    "通":'通',
    "三门":'三门',
    "游戏特点":'游戏特点',
    "番摊是中国古老的做庄游戏，流行于两广一带，当年广摊只有「番」一种玩法，故名番摊，时至今日，番摊玩法已改变不少，除了番之外还多了念、角、通、门、单双这几种玩法。":'番摊是中国古老的做庄游戏，流行于两广一带，当年广摊只有「番」一种玩法，故名番摊，时至今日，番摊玩法已改变不少，除了番之外还多了念、角、通、门、单双这几种玩法。',
    "注意事项":'注意事项',
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。":'部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。',
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。":'桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。',
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。":'荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。',
    "如遇结算错误，按照当期视频结果重新结算。":'如遇结算错误，按照当期视频结果重新结算。',
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。":'如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。',
  },
  FriedGoldenFlower:{
    '炸金花':'炸金花',
    '游戏规则':'游戏规则',
    '使用一副牌游戏（去大小王）进行游戏；':'使用一副牌游戏（去大小王）进行游戏；',
    '先比较牌型大小，若牌型一致再按从大到小的顺序依次比较牌面大小，决定胜负。':'先比较牌型大小，若牌型一致再按从大到小的顺序依次比较牌面大小，决定胜负。',
    '玩法判定':'玩法判定',
    '牌型':'牌型',
    '豹子杀手：':'豹子杀手：',
    '三张非同花的2，3，5；':'三张非同花的2，3，5；',
    '豹子：':'豹子：',
    '三张点数相同的牌；':'三张点数相同的牌；',
    '同花顺：':'同花顺：',
    '花色相同的顺子牌；':'花色相同的顺子牌；',
    '同花：':'同花：',
    '花色相同的任意三张牌；':'花色相同的任意三张牌；',
    '顺子：':'顺子：',
    '三张连续的牌；':'三张连续的牌；',
    '对子：':'对子：',
    '两张点数相同的牌；':'两张点数相同的牌；',
    '散牌：':'散牌：',
    '无法组成任何类型的牌；':'无法组成任何类型的牌；',
    '牌型大小':'牌型大小',
    '>豹子＞同花顺＞同花＞顺子＞对子＞散牌；':'豹子＞同花顺＞同花＞顺子＞对子＞散牌；',
    '豹子杀手＞豹子；':'豹子杀手＞豹子；',
    '当豹子牌和豹子杀手牌同时存在时，豹子杀手牌比豹子牌大；':'当豹子牌和豹子杀手牌同时存在时，豹子杀手牌比豹子牌大；',
    '散牌＞豹子杀手；':'散牌＞豹子杀手；',
    '如不存在豹子牌，豹子杀手是最小的散牌牌型。':'如不存在豹子牌，豹子杀手是最小的散牌牌型。',
    '对子大小':'对子大小',
    '如果两家都是对子牌，即比对对子点数大小，对A＞对K＞对Q＞对J＞对10＞对9＞对8＞对7＞对6＞对5＞对4＞对3＞对2；':'如果两家都是对子牌，即比对对子点数大小，对A＞对K＞对Q＞对J＞对10＞对9＞对8＞对7＞对6＞对5＞对4＞对3＞对2；',
    '两对对子的点数相同，再比单牌点数大小。':'两对对子的点数相同，再比单牌点数大小。',
    '豹子、同花、散牌牌型':'豹子、同花、散牌牌型',
    '比最大那张牌的点数，如最大的那张点数相同，则比第二大牌的点数，以此类推；_A>K>Q>J>10>9>8>7>6>5>4>3>2，花色不分大小。':'比最大那张牌的点数，如最大的那张点数相同，则比第二大牌的点数，以此类推；_A>K>Q>J>10>9>8>7>6>5>4>3>2，花色不分大小。',
    '同花顺，顺子':'同花顺，顺子',
    '按照顺序比点数：AKQ>KQJ>......>432>32A':'按照顺序比点数：AKQ>KQJ>......>432>32A',
    '注意：只有在同花顺和顺子牌型中，A可作为最大或最小的牌，其余牌型中A为最大的单牌':'注意：只有在同花顺和顺子牌型中，A可作为最大或最小的牌，其余牌型中A为最大的单牌',
    '赔率表':'赔率表',
    '玩家赢赔率':'玩家赢赔率',
    '玩家输赔率':'玩家输赔率',
    '胜方牌型':'胜方牌型',
    '平倍':'平倍',
    '翻倍':'翻倍',
    '超倍':'超倍',
    '单牌':'单牌',
    '单牌以下':'单牌{value}以下',
    '对子':'对子',
    '顺子':'顺子',
    '同花':'同花',
    '同花顺':'同花顺',
    '三条':'豹子',
    '豹子杀手':'豹子杀手',
    '游戏特点':'游戏特点',
    '炸金花，也被称为赢三张。是一种广泛流传的民间游戏。':'炸金花，也被称为赢三张。是一种广泛流传的民间游戏。',
    '注意事项':'注意事项',
    '部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。':'部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。',
    '桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。':'桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。',
    '荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单':'荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。 ',
    '如遇结算错误，按照当期视频结果重新结算。':'如遇结算错误，按照当期视频结果重新结算。',
    '如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。':'如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。',
  },
    // 鱼虾蟹
    fishShrimpCrab: {
      '越南鱼虾蟹': '越南鱼虾蟹',
      '游戏规则': '游戏规则',
      '玩家押注的内容与三颗骰子停留开出的图案相同，便赢得彩金。': '玩家押注的内容与三颗骰子停留开出的图案相同，便赢得彩金。',
      '在骰盅里装有3个骰子，每颗骰子分别为：': '在骰盅里装有3个骰子，每颗骰子分别为：',
      '1.鹿': '1.鹿',
      '2.葫芦': '2.葫芦',
      '3.虾': '3.虾',
      '4.鱼': '4.鱼',
      '5.鸡': '5.鸡',
      '6.蟹': '6.蟹',
      '新一轮开始后，25秒投注倒计时开始': '新一轮开始后，25秒投注倒计时开始',
      '您可以选择在任何一个或多个投注区域下注': '您可以选择在任何一个或多个投注区域下注',
      '单笔投注金额不能分别高于或低于单笔投注上限和下限': '单笔投注金额不能分别高于或低于单笔投注上限和下限',
      '投注倒计时结束后，不得再下注，所有已下注均不可更改': '投注倒计时结束后，不得再下注，所有已下注均不可更改',
      '荷官开始按游戏开始键，中心圈内往下移动，并开始震动，持续震动5秒，停摆后，荷官按照骰子输入结果，震动低于4秒，该局游戏则视为无效局': '荷官开始按游戏开始键，中心圈内往下移动，并开始震动，持续震动5秒，停摆后，荷官按照骰子输入结果，震动低于4秒，该局游戏则视为无效局',
      '玩法判定': '玩法判定',
  
      '赔率表': '赔率表',
      '玩法赔率': '玩法赔率',
      '投注项目': '投注项目',
      '赔率': '赔率',
      '说明': '说明',
      '单点': '单点',
      '1:1': '1:1',
      '1:2': '1:2',
      '1:3': '1:3',
      '1:1 投注的指定图案出现1次': '1:1 投注的指定图案出现1次',
      '1:2 投注的指定图案出现2次': '1:2 投注的指定图案出现2次',
      '1:3 投注的指定图案出现3次': '1:3 投注的指定图案出现3次',
  
      '短牌': '短牌',
      '1:5': '1:5',
      '共15组，2个骰子可能出现的图案组合，蟹+葫芦、鱼+虾、葫芦+鸡...，下注的两个图案皆出现算赢。': '共15组，2个骰子可能出现的图案组合，蟹+葫芦、鱼+虾、葫芦+鸡...，下注的两个图案皆出现算赢。',
  
      '全围': '全围',
      '1:30': '1:30',
      '三颗骰子图案相同即全围，包含6种围骰组合，出现任意一种即可胜出。': '三颗骰子图案相同即全围，包含6种围骰组合，出现任意一种即可胜出。',
  
      '长牌/对子': '长牌/对子',
      '1:10': '1:10',
      '三颗骰子中，任意两颗骰子图案相同即为长牌/对子。': '三颗骰子中，任意两颗骰子图案相同即为长牌/对子。',
  
      '游戏特点': '游戏特点',
      '注意事项': '注意事项',
      '桌台限红是本游戏桌台主要玩法的最小、最大玩法限红': '桌台限红是本游戏桌台主要玩法的最小、最大玩法限红',
      '如遇结算错误，按照当期视频结果重新结算': '如遇结算错误，按照当期视频结果重新结算',
      '如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金': '如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金'
    }
};
export default zh;
